export enum BITRIX_URL {
  order = 'https://oneclass.bitrix24.com/crm/deal/details/',
}
export const EDUCATION_GRADE_MAP: Record<
  string,
  {
    label: string;
    grades: {
      label: string;
      value: number;
    }[];
  }
> = {
  K: {
    label: '幼稚園',
    grades: [
      {
        label: '小班',
        value: 1,
      },
      {
        label: '中班',
        value: 2,
      },
      {
        label: '大班',
        value: 3,
      },
    ],
  },
  E: {
    label: '國小',
    grades: [
      {
        label: '一年級',
        value: 1,
      },
      {
        label: '二年級',
        value: 2,
      },
      {
        label: '三年級',
        value: 3,
      },
      {
        label: '四年級',
        value: 4,
      },
      {
        label: '五年級',
        value: 5,
      },
      {
        label: '六年級',
        value: 6,
      },
    ],
  },
  J: {
    label: '國中',
    grades: [
      {
        label: '一年級',
        value: 1,
      },
      {
        label: '二年級',
        value: 2,
      },
      {
        label: '三年級',
        value: 3,
      },
    ],
  },
  H: {
    label: '高中',
    grades: [
      {
        label: '一年級',
        value: 1,
      },
      {
        label: '二年級',
        value: 2,
      },
      {
        label: '三年級',
        value: 3,
      },
    ],
  },
};

export enum BITRIX_CONTACT_MAP {
  ONECLUB_ID = 'UF_CRM_1622627250',
  DESCRIPTION = 'UF_CRM_1620124634',
}
