import { createContext, useContext, useState } from 'react';
import axios from 'plugins/api/axios';
import { IGetCustomersByOneClubIdResponse } from 'api/backstage';
const { backstage } = axios;
interface IUserData extends IGetCustomersByOneClubIdResponse {}
interface IState {
  userProFile: IUserData | null;
  errorUserProFile: string | null;
}
interface IFunction {
  emptyAuthData: () => void;
  emptyUserProFile: () => void;
  getUser: (id: string) => Promise<void>;
}

const useAuth = (): [IState, IFunction] => {
  const [userProFile, setUserProFile] = useState<IUserData | null>(null);
  const [errorUserProFile, setErrorUserProFile] = useState<string | null>(null);

  const emptyAuthData = () => {
    setUserProFile(null);
    setErrorUserProFile(null);
  };

  const emptyUserProFile = () => {
    setUserProFile(null);
  };

  const getUser = async (id: string) => {
    setErrorUserProFile('');
    const response = await backstage.getCustomersByOneClubId(id);
    if (response.status === 'success') {
      setUserProFile(response.data);
    } else {
      setErrorUserProFile(response?.error?.message || null);
    }
  };

  return [
    {
      userProFile,
      errorUserProFile,
    },
    {
      emptyAuthData,
      emptyUserProFile,
      getUser,
    },
  ];
};

function createService<T>(
  func: (...args: any[]) => T,
  initialValue: T | undefined = undefined,
) {
  return createContext(initialValue as T);
}
export const AuthService = createService(useAuth);
export const AuthServiceProvider: React.FC = ({ children }) => {
  return (
    <AuthService.Provider value={useAuth()}>{children}</AuthService.Provider>
  );
};

export const useAuthService = () => useContext(AuthService);
