import { ICreateExamPayload } from '@type/exam.type';
import { AxiosInstance } from 'axios';
import { EApiStatus } from '@type/common';
export default (axios: AxiosInstance, oneExam: string) => ({
  /**
   * 新增速測
   */
  createExam(
    payload: ICreateExamPayload,
  ): Promise<{ status: EApiStatus; content: any }> {
    return axios.post(`${oneExam}/api/Quiz/addCourseQuiz`, payload);
  },
});
