// prettier-ignore
/* eslint-disable no-debugger, no-console */
import { TTL } from 'constants/common';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getRemoteConfig } from 'firebase/remote-config';
import axios from 'plugins/api/axios';
import envConfig from './firebaseConfig.json';

const { electronicCommerce } = axios;
const env = import.meta.env.VITE_ENV;

type TFirebaseConfig = {
  ONE_CLUB_BACKSTAGE_API_KEY: string;
  ONE_CLUB_BACKSTAGE_AUTH_DOMAIN: string;
  ONE_CLUB_BACKSTAGE_PROJECT_ID: string;
  ONE_CLUB_BACKSTAGE_STORAGE_BUCKET: string;
  ONE_CLUB_BACKSTAGE_MESSAGING_SENDER_ID: string;
  ONE_CLUB_BACKSTAGE_APP_ID: string;
  ONE_CLUB_BACKSTAGE_MEASUREMENT_ID: string;
  ONE_CLUB_BACKSTAGE_VAPID_KEY: string;
};
interface IEnvConfig {
  [key: string]: TFirebaseConfig;
}
const config: IEnvConfig = envConfig;
const currentConfig = config[env];

const {
  ONE_CLUB_BACKSTAGE_API_KEY,
  ONE_CLUB_BACKSTAGE_AUTH_DOMAIN,
  ONE_CLUB_BACKSTAGE_PROJECT_ID,
  ONE_CLUB_BACKSTAGE_STORAGE_BUCKET,
  ONE_CLUB_BACKSTAGE_MESSAGING_SENDER_ID,
  ONE_CLUB_BACKSTAGE_APP_ID,
  ONE_CLUB_BACKSTAGE_MEASUREMENT_ID,
  ONE_CLUB_BACKSTAGE_VAPID_KEY,
}: TFirebaseConfig = currentConfig;

const firebaseConfig = {
  apiKey: ONE_CLUB_BACKSTAGE_API_KEY,
  authDomain: ONE_CLUB_BACKSTAGE_AUTH_DOMAIN,
  projectId: ONE_CLUB_BACKSTAGE_PROJECT_ID,
  storageBucket: ONE_CLUB_BACKSTAGE_STORAGE_BUCKET,
  messagingSenderId: ONE_CLUB_BACKSTAGE_MESSAGING_SENDER_ID,
  appId: ONE_CLUB_BACKSTAGE_APP_ID,
  measurementId: ONE_CLUB_BACKSTAGE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);
const messaging = getMessaging();
export const remoteConfig = getRemoteConfig();
// refresh cache 的間隔，若不設定則默認為 12 hr
remoteConfig.settings.minimumFetchIntervalMillis = TTL.ONE_MINUTE * 3;

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: ONE_CLUB_BACKSTAGE_VAPID_KEY,
  })
    .then(async (currentToken: any) => {
      if (currentToken) {
        // Perform any other neccessary action with the token
        await electronicCommerce.postSetFCMKey(currentToken).catch(error => {
          console.log('error:', error);
        });
        // console.log('current token for client: ', currentToken);
      } else {
        // Show permission request UI
        console.log(
          'No registration token available. Request permission to generate one.',
        );
      }
    })
    .catch((err: any) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, (payload: unknown) => {
      // console.log('payload123', payload);
      resolve(payload);
    });
  });

const loc = document.location;
const URL = `http://${loc.host}/src`;

if (process.env.NODE_ENV !== 'production') {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`${URL}/firebase-messaging-sw.js`, { type: 'module' })
      .then(registration => {
        getToken(messaging, {
          vapidKey: ONE_CLUB_BACKSTAGE_VAPID_KEY,
          serviceWorkerRegistration: registration,
        })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .then(token => {
            // console.log(token);
          })
          .catch(err => {
            console.error("Didn't get notification permission", err);
          });
      })
      .catch(error => {
        console.error(
          "😥 L'enregistrement (firebase-messaging-sw.js) ne s'est pas bien passé :",
          error,
        );
      });
  }
}
