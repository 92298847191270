import { dataCentralAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  dataCentralFinanceRead,
  dataCentralSalesRead,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateDataCentralNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(dataCentralAuth)) return null;

  return {
    title: RouteName.datacentral,
    path: path.DATA_CENTRAL,
    icon: ICONS.chartPie,
    children: (() => {
      const newNav = [];
      includeAuth(dataCentralSalesRead) &&
        newNav.push({
          title: FirstRouteName.salesdata,
          path: path.DATA_CENTER_SALES_DATA,
        });
      includeAuth(dataCentralFinanceRead) &&
        newNav.push({
          title: FirstRouteName.financialdata,
          path: path.DATA_CENTER_FINANCIAL_DATA,
        });
      return newNav;
    })(),
  };
};

export { generateDataCentralNav };
