import { reportAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  reportDaily,
  reportPerformanceRead,
  reportRealtime,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateReportNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(reportAuth)) {
    return null;
  }

  return {
    title: RouteName.report,
    path: path.REPORT,
    icon: ICONS.chartPie,
    children: (() => {
      const newNav = [];
      includeAuth(reportRealtime) &&
        newNav.push({
          title: FirstRouteName.realtime,
          path: path.REPORT_REALTIME,
        });

      includeAuth(reportDaily) &&
        newNav.push({
          title: FirstRouteName.daily,
          path: path.REPORT_DAILY,
        });
      includeAuth(reportPerformanceRead) &&
        newNav.push({
          title: FirstRouteName.performance,
          path: path.REPORT_PERFORMANCE,
        });

      return newNav;
    })(),
  };
};

export { generateReportNav };
