import {
  ORDER_PAYMENT_STATUS,
  REVENUES_STATUS_LABEL,
} from 'constants/revenue.constants';
import { Mui } from '@onedesign/ui';
import { alpha } from '@mui/material/styles';

const { Box, styled } = Mui;

const OrderPaymentStatusStyles = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '1px 8px',
  borderRadius: '6px',
  width: 'fit-content',
  '&.normal': {
    backgroundColor: alpha(theme.palette.success.main, 0.16),
    color: theme.palette.success.dark,
  },
  '&.refund': {
    backgroundColor: alpha(theme.palette.warning.main, 0.16),
    color: theme.palette.warning.dark,
  },
  '&.suspend': {
    backgroundColor: alpha(theme.palette.grey[500], 0.16),
    color: theme.palette.grey[600],
  },
  '&.finish': {
    backgroundColor: alpha(theme.palette.info.main, 0.16),
    color: theme.palette.info.dark,
  },
}));

type TProps = {
  status: ORDER_PAYMENT_STATUS;
};
export const OrderPaymentStatus = ({ status }: TProps) => {
  return (
    <OrderPaymentStatusStyles className={status}>
      {REVENUES_STATUS_LABEL[status]()}
    </OrderPaymentStatusStyles>
  );
};
