import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
export default (axios: AxiosInstance, oneClubUsers: string) => ({
  /**
   * 透過 email 或 phone 取得會員資料
   */
  getByMobileOrEmail(params: {
    email?: string;
    mobile?: string;
  }): TApiResponse<any> {
    return axios.get(`${oneClubUsers}/getByMobileOrEmail`, { params });
  },
});
