import { lazy } from 'react';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import {
  Auth,
  Route,
  parentMessageEdit,
  parentMessageRead,
  parentMessageSwitch,
} from '../path';
import { Loadable } from '../utils';

const SendbirdCustomer = Loadable(
  lazy(
    () =>
      import('pages/customerticket/customerchannelmanagement/SendbirdCustomer'),
  ),
);

const generateSendbirdCustomerRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (
    !includeAuth(parentMessageRead) &&
    !includeAuth(parentMessageEdit) &&
    !includeAuth(parentMessageSwitch)
  ) {
    return null;
  }

  return {
    path: Route.sendbirdcustomer,
    element: <SendbirdCustomer />,
  };
};

export { generateSendbirdCustomerRoutes };
