import { AxiosInstance } from 'axios';
import cookies, { KeyCookie } from 'plugins/cookie/cookie';
import {
  ICustomerPayload,
  IGroupPayload,
  IStudentIntoGroupPayload,
} from '@type/audition/vipPass.type';
import { TApiResponse } from '@type/common';
import { ISessionsTrialCountPayload } from '@type/datacenter/dataCenter.type';
import {
  IUpdateGroupPayload,
  TSessionsWatchingRecordsPayload,
  TSessionsWatchingRecordsResponseDataProp,
  IUpdateGroupResponse,
  IGetStaffsPayload,
  IGetStaffsResponse,
  ICreateStaffPayload,
  ICreateStaffResponse,
  IUpdateUserResponse,
} from '@type/liveclass.type';

const organizationId = import.meta.env.VITE_LIVE_CLASS_ORAGANIZATION_ID;
const authorization = cookies.get(KeyCookie.oneClassMMSClient)?.accessToken;

export default (axios: AxiosInstance, liveClass: string) => ({
  // attributes type
  /**
   * 取得 live 客戶資料
   */
  getCustomerData(customerId: string): TApiResponse<any> {
    return axios.get(
      `${liveClass}/organizations/mms/${organizationId}/customers/${customerId}`,
    );
  },
  /**
   * 新增 live 客戶資料
   */
  addCustomerData(payload: ICustomerPayload): TApiResponse<any> {
    return axios.post(
      `${liveClass}/organizations/mms/${organizationId}/customers`,
      payload,
    );
  },
  /**
   * 新增班級
   */
  addGroup(payload: IGroupPayload): TApiResponse<any> {
    return axios.post(
      `${liveClass}/organizations/mms/${organizationId}/groups`,
      payload,
    );
  },
  /**
   * 新增班級
   */
  addStudentIntoGroup(
    groupId: string,
    payload: IStudentIntoGroupPayload,
  ): TApiResponse<any> {
    return axios.post(
      `${liveClass}/organizations/mms/${organizationId}/groups/${groupId}/users`,
      payload,
    );
  },
  /**
   *
   */
  getSessionsTrialCount(
    payload: ISessionsTrialCountPayload,
  ): TApiResponse<any> {
    return axios.get(
      `${liveClass}/organizations/mms/${organizationId}/sessions-trial-count`,
      { params: payload },
    );
  },

  getSessionsWatchingRecords(
    payload: TSessionsWatchingRecordsPayload,
  ): TApiResponse<TSessionsWatchingRecordsResponseDataProp[]> {
    return axios.post(
      `${liveClass}/organizations/sessions-watching-records`,
      payload,
    );
  },
  /**
   * 更新班級
   */
  updateGroup(
    groupId: string,
    payload: IUpdateGroupPayload,
  ): TApiResponse<IUpdateGroupResponse> {
    return axios.put(
      `${liveClass}/organizations/${organizationId}/groups/${groupId}`,
      payload,
      { headers: { authorization } },
    );
  },
  /**
   * 取得 Live 所有 or 單一使用者
   */
  getStaffs(payload: IGetStaffsPayload): TApiResponse<IGetStaffsResponse> {
    return axios.get(`${liveClass}/organizations/${organizationId}/staffs`, {
      params: payload,
      headers: { authorization },
    });
  },
  /**
   * 建立 Live 使用者
   */
  createStaff(
    payload: ICreateStaffPayload,
  ): TApiResponse<ICreateStaffResponse> {
    return axios.post(
      `${liveClass}/organizations/${organizationId}/staffs`,
      payload,
      {
        headers: { authorization },
      },
    );
  },
  /**
   * 更新 Live 使用者
   */
  updateUser(userId: string): TApiResponse<IUpdateUserResponse> {
    return axios.post(
      `${liveClass}/organizations/${organizationId}/users/${userId}`,
      {
        headers: { authorization },
      },
    );
  },
});
