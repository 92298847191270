import { customerVisitorAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  ThirdRoute,
  customerCreate,
  customerDataRead,
  customerDetail,
  visiterDataRead,
} from '../path';
import { Loadable } from '../utils';

const Customer = Loadable(lazy(() => import('pages/customer/Customer')));
const CustomerList = Loadable(
  lazy(() =>
    import('pages/customer/customerlist/CustomerList').then(
      ({ CustomerList }) => ({ default: CustomerList }),
    ),
  ),
);
const CustomerCreate = Loadable(
  lazy(
    () => import('pages/customer/customerlist/customercreate/CustomerCreate'),
  ),
);
const CustomerDetail = Loadable(
  lazy(
    () => import('pages/customer/customerlist/customerdetail/CustomerDetail'),
  ),
);
const CustomerEdit = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/customeredit/CustomerEdit'
      ),
  ),
);

const CustomerStudentCreate = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/studentcreate/StudentCreate'
      ),
  ),
);
const CustomerStudentRead = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/studentread/StudentRead'
      ),
  ),
);
const CustomerStudentEdit = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/studentedit/StudentEdit'
      ),
  ),
);
const CustomerCustomSeriesForm = Loadable(
  lazy(
    () =>
      import(
        'pages/customer/customerlist/customerdetail/customseriesform/CustomSeriesForm'
      ),
  ),
);
const VisitorList = Loadable(
  lazy(() => import('pages/customer/visitorlist/VisitorList')),
);
const VisitorProcessed = Loadable(
  lazy(
    () =>
      import('pages/customer/visitorlist/visitorprocessed/VisitorProcessed'),
  ),
);

const generateCustomerRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(customerVisitorAuth)) {
    return null;
  }

  return {
    path: Route.customer,
    element: <Customer />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(customerDataRead)) {
                  return path.CUSTOMER_LIST;
                }
                if (includeAuth(visiterDataRead)) {
                  return path.VISITOR_LIST;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(customerDataRead) &&
        firstFolder.push({
          path: FirstRoute.customerlist,
          element: <CustomerList />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate
                    to={(() => {
                      if (includeAuth(customerCreate)) {
                        return path.CUSTOMER_CREATE;
                      }
                      if (includeAuth(customerDetail)) {
                        return path.CUSTOMER_DETAIL;
                      }
                      return path.PERMISSION_ABNORMAL;
                    })()}
                  />
                ),
                index: true,
              },
            ];

            includeAuth(customerCreate) &&
              secondFolder.push({
                path: SecondRoute.create,
                element: <CustomerCreate />,
              });

            includeAuth(customerDetail) &&
              secondFolder.push({
                path: SecondRoute.customerdetail,
                element: <CustomerDetail />,
                children: (() => {
                  const thirdFolder: RouteObject[] = [];

                  thirdFolder.push({
                    path: ThirdRoute.edit,
                    element: <CustomerEdit />,
                  });

                  thirdFolder.push({
                    path: ThirdRoute.studentcreate,
                    element: <CustomerStudentCreate />,
                  });

                  thirdFolder.push({
                    path: `${ThirdRoute.studentread}/:studentId`,
                    element: <CustomerStudentRead />,
                  });

                  thirdFolder.push({
                    path: `${ThirdRoute.studentedit}/:studentId`,
                    element: <CustomerStudentEdit />,
                  });

                  thirdFolder.push({
                    path: ThirdRoute.customseriescreate,
                    element: <CustomerCustomSeriesForm />,
                  });

                  thirdFolder.push({
                    path: `${ThirdRoute.customseriesedit}/:seriesCode`,
                    element: <CustomerCustomSeriesForm />,
                  });

                  return thirdFolder;
                })(),
              });
            return secondFolder;
          })(),
        });

      includeAuth(visiterDataRead) &&
        firstFolder.push({
          path: FirstRoute.visitorlist,
          element: <VisitorList />,
          children: (() => {
            const secondFolder: RouteObject[] = [];

            includeAuth([Auth.VISITER_DATA_IMPORT]) &&
              secondFolder.push({
                path: SecondRoute.visitorprocessed,
                element: <VisitorProcessed />,
              });

            return secondFolder;
          })(),
        });

      return firstFolder;
    })(),
  };
};

export { generateCustomerRoutes };
