import { Mui } from '@onedesign/ui';

const { Box, CircularProgress } = Mui;

type LoaderProps = {
  sx?: Mui.SxProps<Mui.Theme>;
  size?: number;
};

// smaller loading
const Loader = ({ sx, size }: LoaderProps) => {
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loader;
