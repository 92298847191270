import { PAYMENT_LABEL } from '@type/electroniccommerce/setting.type';
import { IRevenueList } from '@type/revenue';
import { t } from 'utils/i18n';
import { numberWithCommas } from 'utils/number';
import { Typography } from '@mui/material';
import { fToDate, fToTime } from 'utils/formatTime';
import { Decimal } from 'decimal.js';
import { OrderPaymentStatus } from '../components/OrderPaymentStatus/OrderPaymentStatus';

export enum REVENUE_EXCEL_TYPE {
  SUSPENSE = 'suspense',
  ADVANCE = 'advance',
}

export const REVENUE_EXCEL_TYPE_LABEL = {
  [REVENUE_EXCEL_TYPE.SUSPENSE]: () =>
    t('financial.unearnedRevenue.deferredRevenueReport', '暫收帳款表'),
  [REVENUE_EXCEL_TYPE.ADVANCE]: () =>
    t('financial.unearnedRevenue.unearnedRevenueReport', '預收帳款表'),
};

export enum SEARCH_SELECTS {
  CUSTOMER_NAME = 'customerName',
  ORDER_ID = 'orderId',
  ONECLUB_ID = 'oneClubId',
}

export const SEARCH_SELECTS_LABEL = [
  {
    label: () => t('financial.unearnedRevenue.customerName', '客戶姓名'),
    value: SEARCH_SELECTS.CUSTOMER_NAME,
  },
  {
    label: () => t('financial.unearnedRevenue.orderId', '訂單編號'),
    value: SEARCH_SELECTS.ORDER_ID,
  },
  {
    label: () => t('financial.unearnedRevenue.oneclubId', 'OneClub ID'),
    value: SEARCH_SELECTS.ONECLUB_ID,
  },
];

type TTableConfig = {
  label: () => string;
  key: string;
  transformValue?: (value: IRevenueList) => any;
};

export const TABLE_CONFIG: TTableConfig[] = [
  {
    label: () => t('financial.unearnedRevenue.orderId', '訂單編號'),
    key: 'orderId',
  },
  {
    label: () => t('financial.unearnedRevenue.customerName', '客戶姓名'),
    key: 'customerName',
    transformValue: value => (
      <>
        <Typography variant="subtitle2">{value.customerName}</Typography>
        <Typography variant="body2" sx={{ color: '#637381' }}>
          {value.customerOneClubId}
        </Typography>
      </>
    ),
  },
  {
    label: () => t('financial.unearnedRevenue.payAt', '付款時間'),
    key: 'payAt',
    transformValue: value => (
      <>
        <Typography variant="subtitle2">{fToDate(value.payAt)}</Typography>
        <Typography variant="body2" sx={{ color: '#637381' }}>
          {fToTime(value.payAt)}
        </Typography>
      </>
    ),
  },
  {
    label: () => t('financial.unearnedRevenue.amt', '訂單總額'),
    key: 'amt',
    transformValue: value => (
      <>
        <Typography variant="subtitle2">
          {numberWithCommas(value.amt)}
        </Typography>
        <Typography variant="body2" sx={{ color: '#637381' }}>
          {numberWithCommas(
            new Decimal(value.amt).dividedBy(1.05).round().toNumber(),
          )}{' '}
          未稅
        </Typography>
      </>
    ),
  },
  {
    label: () => t('financial.unearnedRevenue.payment', '金流'),
    key: 'payment',
    transformValue: value => PAYMENT_LABEL[value.payment],
  },
  {
    label: () => t('financial.unearnedRevenue.installment', '期數'),
    key: 'installment',
  },
  {
    label: () => t('financial.unearnedRevenue.paidInstallment', '已繳期數'),
    key: 'paidInstallment',
  },
  {
    label: () => t('financial.unearnedRevenue.status', '訂單繳款狀態'),
    key: 'status',
    transformValue: value => <OrderPaymentStatus status={value.status} />,
  },
  {
    label: () => t('financial.unearnedRevenue.prepaidAmt', '預收款金額(未稅)'),
    key: 'prepaidAmt',
    transformValue: value => (
      <Typography>
        {numberWithCommas(
          new Decimal(value.prepaidAmt).dividedBy(1.05).round().toNumber(),
        )}
      </Typography>
    ),
  },
];
