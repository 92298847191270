// sentry
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

export default function initSentry() {
  if (import.meta.env.VITE_ENV === 'dev') {
    return;
  }
  Sentry.init({
    dsn: 'https://ef1e896a67924dfb93ca2e7f017f85ff@o1420729.ingest.sentry.io/4504286261346304',
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
