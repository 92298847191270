import { authorityAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  authorityDepartment,
  authorityEmployee,
  authorityRole,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateAuthorityNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(authorityAuth)) return null;

  return {
    title: RouteName.authority,
    path: path.AUTHORITY,
    icon: ICONS.userConfig,
    children: (() => {
      const newNav = [];

      includeAuth(authorityEmployee) &&
        newNav.push({
          title: FirstRouteName.employee,
          path: path.AUTHORITY_EMPLOYEE,
        });

      includeAuth(authorityRole) &&
        newNav.push({
          title: FirstRouteName.role,
          path: path.AUTHORITY_ROLE,
        });

      includeAuth(authorityDepartment) &&
        newNav.push({
          title: FirstRouteName.department,
          path: path.AUTHORITY_DEPARTMENT,
        });

      return newNav;
    })(),
  };
};

export { generateAuthorityNav };
