import qs from 'qs';
import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import {
  GetSubjectIssueMessageListParams,
  GetSubjectIssueMessageListResponse,
  IGetSubjectIssueListParams,
  IGetSubjectIssueListResponse,
} from './oneAnswer.type';

export default (axios: AxiosInstance, oneAnswer: string) => ({
  /** 後台取得問題列表 */
  getSubjectIssueList(
    data: IGetSubjectIssueListParams,
  ): TApiResponse<IGetSubjectIssueListResponse> {
    const params = qs.stringify(data, { indices: false });
    return axios.get(
      `${oneAnswer}/subjectIssue/backstage/getSubjectIssueList?${params}`,
    );
  },
  /**
   * 後台取得問題訊息列表
   */
  getSubjectIssueMessageList(
    channelURL: string,
    data: GetSubjectIssueMessageListParams,
  ): TApiResponse<GetSubjectIssueMessageListResponse> {
    return axios.get(
      `${oneAnswer}/subjectIssue/backstage/getBackstageSubjectIssueMessageList/${channelURL}`,
      { params: data },
    );
  },
});
