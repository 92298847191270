import { AxiosInstance } from 'axios';
import { TRCApiResponse } from '@type/common';
import {
  ICity,
  IGetSchoolsVersionsParams,
  ISchoolVersion,
} from '@type/school.type';

export default (axios: AxiosInstance, school: string) => ({
  /**
   * 取得縣市鄉鎮
   **/
  getCityAndDistricts(): TRCApiResponse<ICity[]> {
    return axios.post(`${school}/api/school/districts`);
  },
  /**
   * 取得學校各年級科目版本
   **/
  getSchoolVersions(
    params: IGetSchoolsVersionsParams,
  ): TRCApiResponse<ISchoolVersion[]> {
    return axios.post(`${school}/api/school/versions`, params);
  },
});
