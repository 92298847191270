import { dataCentralAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  dataCentralFinanceRead,
  dataCentralSalesRead,
} from '../path';
import { Loadable } from '../utils';

const DataCenter = Loadable(lazy(() => import('pages/datacenter/DataCenter')));
const DataCenterSalesData = Loadable(
  lazy(() => import('pages/datacenter/salesdata/SalesData')),
);
const DataCenterFinancialData = Loadable(
  lazy(() => import('pages/datacenter/financialdata/FinancialData')),
);

const generateDataCentralRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(dataCentralAuth)) {
    return null;
  }

  return {
    path: Route.datacentral,
    element: <DataCenter />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(dataCentralSalesRead)) {
                  return path.DATA_CENTER_SALES_DATA;
                }
                if (includeAuth(dataCentralFinanceRead)) {
                  return path.DATA_CENTER_FINANCIAL_DATA;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(dataCentralSalesRead) &&
        firstFolder.push({
          path: FirstRoute.salesdata,
          element: <DataCenterSalesData />,
        });
      includeAuth(dataCentralFinanceRead) &&
        firstFolder.push({
          path: FirstRoute.financialdata,
          element: <DataCenterFinancialData />,
        });
      return firstFolder;
    })(),
  };
};

export { generateDataCentralRoutes };
