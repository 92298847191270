import { tutorialCenterAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  ThirdRoute,
  tutorialCenterCourseRead,
} from '../path';
import { Loadable } from '../utils';

const TutorialCenter = Loadable(
  lazy(() => import('pages/tutorialCenter/TutorialCenter')),
);
const TutorialCenterFormalCourse = Loadable(
  lazy(() => import('pages/tutorialCenter/formalCourse/FormalCourse')),
);
const TutorialCenterCourseItem = Loadable(
  lazy(() => import('pages/tutorialCenter/formalCourse/courseForm/CourseForm')),
);
const TutorialCenterCourseItemOneLinkPlusCourseForm = Loadable(
  lazy(
    () =>
      import(
        'pages/tutorialCenter/formalCourse/oneLinkPlusCourseForm/OneLinkPlusCourseForm'
      ),
  ),
);

const generateTutorialCenterRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(tutorialCenterAuth)) {
    return null;
  }

  return {
    path: Route.tutorialcenter,
    element: <TutorialCenter />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(tutorialCenterCourseRead)) {
                  return path.TUTORIAL_CENTER_FORMAL_COURSE;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
        },
      ];
      if (includeAuth(tutorialCenterCourseRead)) {
        firstFolder.push({
          path: FirstRoute.course,
          element: <TutorialCenterFormalCourse />,
          children: (() => {
            const secondFolder: RouteObject[] = [];

            includeAuth([Auth.TUTORIAL_CENTER_COURSE_CREATE]) &&
              secondFolder.push({
                path: SecondRoute.create,
                element: <TutorialCenterCourseItem />,
                index: true,
              });

            secondFolder.push({
              path: SecondRoute.read,
              children: [
                {
                  element: <Navigate to={path.NOT_FOUND} />,
                  index: true,
                },
                {
                  path: Route.id,
                  element: <TutorialCenterCourseItem />,
                },
              ],
            });

            includeAuth([Auth.TUTORIAL_CENTER_COURSE_CREATE]) &&
              secondFolder.push({
                path: SecondRoute.copy,
                children: [
                  {
                    element: <Navigate to={path.NOT_FOUND} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <TutorialCenterCourseItem />,
                  },
                ],
              });

            includeAuth([Auth.TUTORIAL_CENTER_COURSE_FORMAL_EDIT]) &&
              secondFolder.push({
                path: SecondRoute.edit,
                children: [
                  {
                    element: <Navigate to={path.NOT_FOUND} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <TutorialCenterCourseItem />,
                  },
                ],
              });

            // OneLink+
            secondFolder.push({
              path: SecondRoute.onelinkplus,
              children: [
                {
                  path: ThirdRoute.create,
                  element: <TutorialCenterCourseItemOneLinkPlusCourseForm />,
                  index: true,
                },
                {
                  path: ThirdRoute.read,
                  children: [
                    {
                      path: Route.id,
                      element: (
                        <TutorialCenterCourseItemOneLinkPlusCourseForm />
                      ),
                      index: true,
                    },
                  ],
                },
                {
                  path: ThirdRoute.edit,
                  children: [
                    {
                      path: Route.id,
                      element: (
                        <TutorialCenterCourseItemOneLinkPlusCourseForm />
                      ),
                      index: true,
                    },
                  ],
                },
                {
                  path: ThirdRoute.copy,
                  children: [
                    {
                      path: Route.id,
                      element: (
                        <TutorialCenterCourseItemOneLinkPlusCourseForm />
                      ),
                      index: true,
                    },
                  ],
                },
              ],
            });

            return secondFolder;
          })(),
        });
      }
      return firstFolder;
    })(),
  };
};

export { generateTutorialCenterRoutes };
