import { tutorialCenterAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  tutorialCenterCourseRead,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateTutorialCenterNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(tutorialCenterAuth)) {
    return null;
  }

  return {
    title: RouteName.tutorialcenter,
    path: path.TUTORIAL_CENTER,
    icon: ICONS.tutorialCenter,
    children: (() => {
      const newNav = [];
      if (includeAuth(tutorialCenterCourseRead)) {
        newNav.push({
          title: FirstRouteName.course,
          path: path.TUTORIAL_CENTER_FORMAL_COURSE,
        });
      }
      return newNav;
    })(),
  };
};

export { generateTutorialCenterNav };
