import { getUserLanguagePreference, language } from 'utils/i18n';

const languageSystem = getUserLanguagePreference();
export interface SwitchLanguageValueType<T> {
  logoZh: T;
  logoEn: T;
}
function changeByLanguageSystem<T>(valueSet: SwitchLanguageValueType<T>) {
  let logoImg: T = valueSet.logoEn;
  switch (languageSystem) {
    case language.zh: {
      logoImg = valueSet.logoZh;
      break;
    }
    case language.en: {
      logoImg = valueSet.logoEn;
      break;
    }
    default: {
      break;
    }
  }
  return logoImg;
}
export { changeByLanguageSystem };
