import { AxiosInstance } from 'axios';
// import { removeUndefinedFromObject } from 'utils/common';
// import { IPagnationParams } from '@type/common';

interface ITeacherLeavePayload {
  teacherId: string;
  reason: string;
  startAt: string;
  endAt: string;
  role: 'teacher' | 'staff';
}

export default (axios: AxiosInstance, leave: string) => ({
  /**
   * 老師請假
   */
  teacherLeave(data: ITeacherLeavePayload): any {
    return axios.post(`${leave}/leave/teacher/duration`, data);
  },
});
