import { ALL_REASON_OF_CHANGE } from '@type/point/points.type';

const ALL_REASON_OF_CHANGE_TEXT = {
  [ALL_REASON_OF_CHANGE.CREATE]: '新增訂單',
  [ALL_REASON_OF_CHANGE.RENEW]: '續約',
  [ALL_REASON_OF_CHANGE.RESERVATION]: '預備開課',
  [ALL_REASON_OF_CHANGE.BACKTRACK]: '回朔',
  [ALL_REASON_OF_CHANGE.CUSTOMER_COMPLAINT]: '品質不佳',
  [ALL_REASON_OF_CHANGE.MGM]: 'MGM',
  [ALL_REASON_OF_CHANGE.OTHER]: '其他',
  [ALL_REASON_OF_CHANGE.CANCEL_ORDER]: '取消訂單',
};

export { ALL_REASON_OF_CHANGE_TEXT };
