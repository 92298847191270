// routes
import {
  RtlLayout,
  ScrollToTop,
  ThemeColorPresets,
  MotionLazyContainer,
  ProgressBarStyle,
} from '@onedesign/ui';
import Client500 from 'pages/Client500';
import { ErrorBoundary } from 'react-error-boundary';
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AuthServiceProvider } from 'services/AuthService';
import { PointsServiceProvider } from 'services/PointsService';
import { SnackbarProvider } from 'notistack';
import { LoadingProvider } from 'services/LoadingService';
import { LoginProvider } from 'services/LoginService';
import { RemoteConfigProvider } from 'services/RemoteConfigService';
import Root from 'pages/Root';
import { toast, ToastContainer } from 'react-toastify';
import { TranslationProvider } from 'services/TranslationService';
import ThemeProvider from './theme/ThemeProvider';

// ----------------------------------------------------------------------
const defaultStaleTime = 5 * 60 * 1000; // 5 minutes
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: defaultStaleTime,
    },
  },
  queryCache: new QueryCache({
    onError: error => {
      if (error instanceof Error) {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    },
  }),
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthServiceProvider>
        <PointsServiceProvider>
          <SnackbarProvider>
            <ThemeProvider>
              <TranslationProvider>
                <ThemeColorPresets>
                  <RtlLayout>
                    <MotionLazyContainer>
                      <ProgressBarStyle />
                      <ErrorBoundary FallbackComponent={Client500}>
                        <ScrollToTop />
                        <ToastContainer />
                        <LoadingProvider>
                          <RemoteConfigProvider>
                            <LoginProvider>
                              <Root />
                            </LoginProvider>
                          </RemoteConfigProvider>
                        </LoadingProvider>
                      </ErrorBoundary>
                    </MotionLazyContainer>
                  </RtlLayout>
                </ThemeColorPresets>
              </TranslationProvider>
            </ThemeProvider>
          </SnackbarProvider>
        </PointsServiceProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </AuthServiceProvider>
    </QueryClientProvider>
  );
}
