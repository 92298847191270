import { MAIL_KEY } from 'constants/mail.constants';

// payment-flow
export enum EPaymentStatus {
  active = 'active',
  disable = 'disable',
}

export enum EPayment {
  newebpay = 'newebpay', // 藍星
  ecpay = 'ecpay', // 綠界
  chailease = 'chailease', // 中租
  farTrust = 'farTrust', // 遠信
  paynow = 'paynow',
  transfer = 'transfer', // 轉帳
  noCashTrade = 'noCashTrade', // 0元訂單
  payuni = 'payuni', // PAYUNi
}
export enum EPaymentFlow {
  credit = 'credit', // 信用卡
  noCard = 'noCard', // 銀角零卡分期
  farTrustNoCard = 'farTrustNoCard', // 遠信免卡分期
  noCashTrade = 'noCashTrade', // 0元訂單
  transfer = 'transfer', // 轉帳
  costAveraging = 'costAveraging', // 定期定額
}
export enum EPaymentSettingType {
  add = 'add', // 開啟金流設定
  update = 'update', // 變更金流設定
  delete = 'delete', // 關閉金流設定
}

export const PAYMENT_LABEL = {
  [EPayment.newebpay]: '藍新',
  [EPayment.ecpay]: '綠界',
  [EPayment.chailease]: '中租',
  [EPayment.farTrust]: '遠信',
  [EPayment.paynow]: 'PayNow',
  [EPayment.transfer]: '轉帳',
  [EPayment.noCashTrade]: '0元訂單',
  [EPayment.payuni]: 'PAYUNi 統一金流',
};

export const PAYMENT_FLOW_LABEL = {
  [EPaymentFlow.credit]: '信用卡',
  [EPaymentFlow.noCard]: '銀角零卡分期',
  [EPaymentFlow.farTrustNoCard]: '遠信免卡分期',
  [EPaymentFlow.noCashTrade]: '0元訂單',
  [EPaymentFlow.transfer]: '轉帳付款',
  [EPaymentFlow.costAveraging]: '定期定額',
};
export const PAYMENT_RECORD_TYPE = {
  [EPaymentSettingType.add]: '新增',
  [EPaymentSettingType.update]: '變更',
  [EPaymentSettingType.delete]: '移除',
};

export type TInstallment = {
  installment: number;
  status: EPaymentStatus;
  payment?: EPayment;
};

export type TPayment = {
  installments?: TInstallment[] | undefined;
  status: EPaymentStatus;
};

export type TPaymentFlow =
  | EPaymentFlow.credit
  | EPaymentFlow.noCard
  | EPaymentFlow.farTrustNoCard
  | EPaymentFlow.noCashTrade
  | EPaymentFlow.transfer
  | EPaymentFlow.costAveraging;

export type TPaymentSettingDto = {
  [key in EPaymentFlow]: key extends EPaymentFlow.costAveraging
    ? { paynow: TPayment; payuni: TPayment }
    : TPayment;
};

export type TPaymentSettingResponseDto = TPaymentSettingDto & {
  id: string;
  createdAt: number;
};

export enum CONDITION_TYPE {
  IS_PAY_OFF = 'isPayOff',
  PRODUCTS_CONDITION_GROUP = 'productsConditionGroup',
  PLANS_CONDITION_GROUP = 'plansConditionGroup',
  STAFFS_CONDITION_GROUP = 'staffsConditionGroup',
}

export enum STRATEGY_TYPE {
  ACTIVITY = 'activity',
  MATCHING = 'matching',
  GIVEAWAY = 'giveaway',
  PRICE_DEDUCT = 'priceDeduct',
  PRICE_DISCOUNT = 'priceDiscount',
}

export interface IConditionEditData {
  type: CONDITION_TYPE;
  conditionData: string[];
}

export interface ISpuToSkuList {
  id: string;
  option: { label: string; value: string };
  price: number | string;
  quantity: number;
}

export interface IPromotionSku {
  quantity: number;
  sku: {
    id: string;
    name: string;
    price: number;
  };
}

export interface ISkuList {
  duration: number;
  skus: IPromotionSku[];
}

export interface IStrategyEditData {
  type: STRATEGY_TYPE;
  strategyData: {
    duration?: number;
    skus?: IPromotionSku[];
    discount?: number;
  };
}

export interface IStrategy {
  type: STRATEGY_TYPE;
  duration?: number;
  skus?: IPromotionSku[];
  discount?: number;
}

export interface IMailSettingData {
  address: string;
  activation: boolean;
  type: 'customer' | 'other';
}

export type TMailSetting = {
  [key in MAIL_KEY]: IMailSettingData[];
};
export interface IPaymentSettingDepartment {
  id: string;
  name: string;
}
export interface IPaymentSettingModification {
  type: EPaymentSettingType;
  status: EPaymentStatus;
  paymentMethod: EPaymentFlow;
  installment: number;
  installmentStatus: EPaymentStatus;
  payment: EPayment;
  oldPayment: EPayment;
}
export interface IPaymentSettingRecord {
  id: string;
  reason: string;
  createdAt: Date;
  paymentSetting: string;
  editor: {
    name: string;
    staffNumber: string;
    departments: IPaymentSettingDepartment[];
    id: string;
  };
  modification: IPaymentSettingModification[];
}

export interface IPaymentRecordResponse {
  total: number;
  paymentSettingsLogs: IPaymentSettingRecord[];
}

export interface IGetPaymentSettingParams {
  limit: number;
  skip: number;
}
