import path, { FirstRouteName, RouteName } from 'routes/path';
import { ICONS } from './icon';

const generateAdvertisementNav = () => {
  return {
    title: RouteName.advertisement,
    path: path.ADVERTISEMENT,
    icon: ICONS.advertisement,
    children: (() => {
      const newNav = [];

      newNav.push({
        title: FirstRouteName.liveapp,
        path: path.ADVERTISEMENT_LIVE_APP,
      });

      return newNav;
    })(),
  };
};

export { generateAdvertisementNav };
