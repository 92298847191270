import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { IUserData } from 'services/LoginService';
import path, { FirstRoute, Route } from '../path';
import { Loadable } from '../utils';

const OneAnswer = Loadable(lazy(() => import('pages/oneAnswer/OneAnswer')));
const OneAnswerList = Loadable(
  lazy(() => import('pages/oneAnswer/oneAnswerList/OneAnswerList')),
);

const generateOneAnswerRoutes = (loginUser: IUserData | null) => {
  if (!loginUser) {
    return null;
  }

  return {
    path: Route.oneAnswer,
    element: <OneAnswer />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                return path.ONE_ANSWER_RECORD;
              })()}
            />
          ),
          index: true,
        },
      ];

      firstFolder.push({
        path: FirstRoute.record,
        element: <OneAnswerList />,
      });

      return firstFolder;
    })(),
  };
};

export { generateOneAnswerRoutes };
