import { Buffer } from 'buffer';
export const base64ToBlob = (data: string): Blob => {
  const byteString = atob(data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab]);
};

export const formatSizeUnitsMB = (data: number): string => {
  return (data / (1024 * 1024)).toFixed(2);
};

export const formatNumberWithCommas = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const encodeToBase64 = (text: string): string => {
  const encodedString = window.btoa(text);
  return encodedString;
};
export const decodeBase64 = (base64String: string): string => {
  const decodedBuffer = Buffer.from(base64String, 'base64');
  const decodedString = decodedBuffer.toString('utf8');
  return decodedString;
};
