import { AxiosInstance } from 'axios';
import { TApiResponse } from 'src/@type/common';
import {
  ICheckCourseCanPayBackParams,
  IPayBackCreditPayload,
} from './credit.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /** 檢查該課堂是否可以補扣 */
  checkCourseCanPayBack(
    params: ICheckCourseCanPayBackParams,
  ): TApiResponse<boolean> {
    return axios.get(`${apiPrefix}/credit/checkCourseCanPayBack`, { params });
  },

  /** 補扣堂數 */
  payBackCredit(payload: IPayBackCreditPayload): TApiResponse<boolean> {
    return axios.post(`${apiPrefix}/credit/payBackCredit`, payload);
  },
});
