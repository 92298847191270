import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  IGetTosListParams,
  IGetTosListResponse,
  IAmendmenTosPayload,
  IAmendmenTosResponse,
  IGetTosByVersionResponse,
} from '@type/oneClassNow.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  getTosList(params: IGetTosListParams): TApiResponse<IGetTosListResponse> {
    return axios.get(`${apiPrefix}/backend/oneclass-now/tos`, {
      params,
    });
  },
  amendmentTos(
    payload: IAmendmenTosPayload,
  ): TApiResponse<IAmendmenTosResponse> {
    return axios.post(`${apiPrefix}/backend/oneclass-now/tos`, payload);
  },
  getTosByVersion(version: string): TApiResponse<IGetTosByVersionResponse> {
    return axios.get(`${apiPrefix}/external/oneclass-now/tos/${version}`);
  },
});
