import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import { TGetRecordingTimeResponse } from '@type/oneborad';

export default (axios: AxiosInstance, oneBoard: string) => ({
  /**
   * 取得 oneboard record
   */
  getMultiUserRecord(courseId: string): any {
    return axios.post(`${oneBoard}/oneboard/course/multiUserRecord`, {
      courseId,
    });
  },

  /**
   * 取得 oneboard 錄影時間
   */
  getRecordingTime(courseId: string): TApiResponse<TGetRecordingTimeResponse> {
    return axios.get(
      `${oneBoard}/agora/get_recording_times?roomId=${courseId}`,
    );
  },
  getVideoUser(data: any): any {
    return axios.get(
      `${import.meta.env.VITE_ONEBOARD_API_URL}/oneboard/course`,
      { params: data },
    );
  },
});
