import { shipManagementAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  badgeRead,
  textbookRead,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateShipManagementNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(shipManagementAuth)) {
    return null;
  }

  return {
    title: RouteName.badgecentral,
    path: path.MEDAL_CENTRAL,
    icon: ICONS.badgeCentral,
    children: (() => {
      const newNav = [];

      includeAuth(badgeRead) &&
        newNav.push({
          title: FirstRouteName.rewardshipmentmanagement,
          path: path.MEDAL_CENTRAL_REWARD_REDEMPTION,
        });

      includeAuth(textbookRead) &&
        newNav.push({
          title: FirstRouteName.textbookmangement,
          path: path.TEXTBOOK_MANAGEMENT,
        });

      return newNav;
    })(),
  };
};

export { generateShipManagementNav };
