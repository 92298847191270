import { AxiosInstance } from 'axios';
import {
  IAudition,
  IContactDataResponse,
  ICRMDealResponse,
  IDealDataResponse,
  IGradeDataResponse,
  IUpdateCRMDealParams,
} from '@type/audition/vipPass.type';
import { TApiResponse, EApiStatus } from '@type/common';
export default (axios: AxiosInstance, bitrixHook: string) => ({
  // attributes type
  /**
   * 取得CRM資料
   */
  async getCRMDealData(bitrixId: string): TApiResponse<ICRMDealResponse> {
    const crmDealResponse = (await axios.get(
      `${bitrixHook}/rest/9/aqw6va2ru41h8d7t/crm.deal.get.json`,
      {
        params: { id: bitrixId },
      },
    )) as IDealDataResponse;
    const crmDealData = crmDealResponse?.result;
    if (crmDealData) {
      const contactResponse = (await axios.get(
        `${bitrixHook}/rest/9/aqw6va2ru41h8d7t/crm.contact.get.json`,
        {
          params: { id: crmDealData.CONTACT_ID },
        },
      )) as IContactDataResponse;
      const contactData = contactResponse?.result;
      if (contactData) {
        const auditions: IAudition[] = [
          {
            name: '試聽資訊1',
            value: 'firstAudition',
            teacherName: crmDealData.UF_CRM_1620125452417,
            studentName: crmDealData.UF_CRM_1628646513,
            bookIds: crmDealData.UF_CRM_1628646299,
            gradeFieldKey: 'UF_CRM_614ABE738FF1A',
            grade: crmDealData.UF_CRM_614ABE738FF1A,
          },
          {
            name: '試聽資訊2',
            value: 'secondAudition',
            teacherName: crmDealData.UF_CRM_1623036815,
            studentName: crmDealData.UF_CRM_1628646522,
            bookIds: crmDealData.UF_CRM_1628646309,
            gradeFieldKey: 'UF_CRM_1661240840',
            grade: crmDealData.UF_CRM_1661240840,
          },
          {
            name: '試聽資訊3',
            value: 'thirdAudition',
            teacherName: crmDealData.UF_CRM_1623036840,
            studentName: crmDealData.UF_CRM_1628646544,
            bookIds: crmDealData.UF_CRM_1628646319,
            gradeFieldKey: 'UF_CRM_1661240887',
            grade: crmDealData.UF_CRM_1661240887,
          },
          {
            name: '試聽資訊4',
            value: 'fourthAudition',
            teacherName: crmDealData.UF_CRM_1623036853,
            studentName: crmDealData.UF_CRM_1628646553,
            bookIds: crmDealData.UF_CRM_1628646328,
            gradeFieldKey: 'UF_CRM_1661240930',
            grade: crmDealData.UF_CRM_1661240930,
          },
        ];
        const gradesPromises = auditions.map(item =>
          axios.get(
            `${bitrixHook}/rest/176/khh0ore3ktp012ua/crm.deal.userfield.list.json`,
            {
              params: {
                [`FILTER[FIELD_NAME]`]: item.gradeFieldKey,
              },
            },
          ),
        );
        const responses = (await Promise.all(
          gradesPromises,
        )) as unknown as IGradeDataResponse[];
        responses.forEach((response, responseIndex) => {
          const list = response.result?.[0]?.LIST;
          if (list) {
            auditions[responseIndex].grade =
              list.find(item => item.ID === auditions[responseIndex].grade)
                ?.VALUE || '';
          }
        });
        return Promise.resolve({
          status: EApiStatus.SUCCESS,
          data: {
            oneClubId: crmDealData.UF_CRM_1661342460,
            phone: contactData.PHONE?.[0]?.VALUE || '',
            email: contactData.EMAIL?.[0]?.VALUE || '',
            auditions,
            lastName: contactData.LAST_NAME,
            name: contactData.NAME,
            address: contactData.ADDRESS,
          },
        });
      }
      return Promise.resolve({
        status: EApiStatus.FAILURE,
        data: {
          oneClubId: '',
          phone: '',
          auditions: [],
          email: '',
          address: '',
        },
      });
    } else {
      return Promise.resolve({
        status: EApiStatus.FAILURE,
        data: {
          oneClubId: '',
          phone: '',
          auditions: [],
          email: '',
          address: '',
        },
      });
    }
  },
  async updateCRMDealData(params: IUpdateCRMDealParams): TApiResponse<any> {
    const { dealId, fieldIdOneClubId, oneClubId } = params;
    const response = await axios.get(
      `${bitrixHook}/rest/9/sbrsgo8c3qxoxouz/crm.deal.update.json`,
      {
        params: {
          id: dealId,
          [`FIELDS[${fieldIdOneClubId}]`]: oneClubId,
        },
      },
    );
    return Promise.resolve({
      status: EApiStatus.SUCCESS,
      data: response,
    });
  },
});
