import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounce, Page } from '@onedesign/ui';
// assets
import { MaintenanceIllustration } from '../assets';
import { RootStyle, LinkStyle } from './Error.style';

// ----------------------------------------------------------------------

function refreshPage() {
  window.location.reload();
}

export default function Client500() {
  return (
    <Page title="" sx={{ height: 1 }}>
      <RootStyle>
        <Container component={MotionContainer}>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                唉呀，網頁載入失敗
              </Typography>
            </m.div>
            <Typography sx={{ color: 'text.secondary' }}>
              網頁發生錯誤，請嘗試{' '}
              <LinkStyle onClick={refreshPage}>重新整理頁面</LinkStyle>
            </Typography>

            <m.div variants={varBounce().in}>
              <MaintenanceIllustration
                sx={{ height: 260, my: { xs: 5, sm: 10 } }}
              />
            </m.div>

            <Button
              to="/"
              size="large"
              variant="contained"
              component={RouterLink}
            >
              返回首頁
            </Button>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}
