import { teachersManagementAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  teacherApplicationRead,
  teacherApplicationReadUnverified,
  teacherContractRead,
  teacherListRead,
  teacherMaterialRead,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateTeachersManagementNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(teachersManagementAuth)) {
    return null;
  }

  return {
    title: RouteName.teachersmanagement,
    path: path.TEACHER_MANAGEMENT,
    icon: ICONS.teacherManagement,
    children: (() => {
      const newNav = [];
      includeAuth(teacherListRead) &&
        newNav.push({
          title: FirstRouteName.teacherlist,
          path: path.TEACHER_MANAGEMENT_LIST,
        });

      includeAuth(teacherApplicationRead) &&
        newNav.push({
          title: FirstRouteName.teacherinterviewlist,
          path: path.TEACHER_INTERVIEW_MANAGEMENT_LIST,
        });

      includeAuth(teacherApplicationReadUnverified) &&
        newNav.push({
          title: FirstRouteName.teacherunverifiedlist,
          path: path.TEACHER_UNVERIFIED_MANAGEMENT_LIST,
        });
      includeAuth(teacherApplicationReadUnverified) &&
        newNav.push({
          title: FirstRouteName.referrersList,
          path: path.REFERRERS_MANAGEMENT_LIST,
        });
      includeAuth(teacherMaterialRead) &&
        newNav.push({
          title: FirstRouteName.teachermaterial,
          path: path.TEACHER_MANAGEMENT_MATERIAL,
        });

      includeAuth(teacherContractRead) &&
        newNav.push({
          title: FirstRouteName.contract,
          path: path.TEACHER_SETTING,
        });

      return newNav;
    })(),
  };
};

export { generateTeachersManagementNav };
