import { reportAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  ThirdRoute,
  reportDaily,
  reportPerformanceRead,
  reportRealtime,
} from '../path';
import { Loadable } from '../utils';

const Report = Loadable(lazy(() => import('pages/report/Report')));
const ReportDaily = Loadable(lazy(() => import('pages/report/daily/Daily')));
const ReportRealTime = Loadable(
  lazy(() => import('pages/report/realtime/RealTime')),
);
const ReportPerformance = Loadable(
  lazy(() =>
    import('pages/report/performance/Performance').then(({ Performance }) => ({
      default: Performance,
    })),
  ),
);
const ReportPerformanceDetail = Loadable(
  lazy(() =>
    import('pages/report/performance/performancedetail/PerformanceDetail').then(
      ({ PerformanceDetail }) => ({ default: PerformanceDetail }),
    ),
  ),
);

const generateReportRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(reportAuth)) {
    return null;
  }

  return {
    path: Route.report,
    element: <Report />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(reportRealtime)) {
                  return path.REPORT_REALTIME;
                }
                if (includeAuth(reportDaily)) {
                  return path.REPORT_DAILY;
                }
                if (includeAuth(reportPerformanceRead)) {
                  return path.REPORT_PERFORMANCE;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(reportDaily) &&
        firstFolder.push({
          path: FirstRoute.daily,
          element: <ReportDaily />,
        });
      includeAuth(reportRealtime) &&
        firstFolder.push({
          path: FirstRoute.realtime,
          element: <ReportRealTime />,
        });
      includeAuth(reportPerformanceRead) &&
        firstFolder.push({
          path: FirstRoute.performance,
          element: <ReportPerformance />,
          children: (() => {
            const secondFolder: RouteObject[] = [];
            secondFolder.push({
              path: SecondRoute.performancedetail,
              element: <ReportPerformanceDetail />,
              children: (() => {
                const thirdFolder: RouteObject[] = [];
                includeAuth([Auth.PERFORMANCE_EDIT]) &&
                  thirdFolder.push({
                    path: ThirdRoute.edit,
                    element: <ReportPerformanceDetail />,
                  });
                return thirdFolder;
              })(),
            });
            return secondFolder;
          })(),
        });

      return firstFolder;
    })(),
  };
};

export { generateReportRoutes };
