// import LRU from 'lru-cache';
import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  ICreateBonusPayload,
  IGetAllBonusTotalParams,
  IGetAllBonusTotalResponse,
  IGetBonusParams,
  IGetBonusResponse,
  BonusPayload,
} from '@type/financial/bonus.type';

export default (axios: AxiosInstance, bonus: string) => ({
  /**
   * 取得老師本月加給列表
   */
  getBonusList(data: IGetBonusParams): TApiResponse<IGetBonusResponse> {
    return axios.get(`${bonus}`, { params: data });
  },
  /**
   * 取得老師本月加給總計
   */
  getAllBonusTotal(
    data: IGetAllBonusTotalParams,
  ): TApiResponse<IGetAllBonusTotalResponse> {
    return axios.get(`${bonus}/total`, { params: data });
  },
  /**
   * 新增本月加給
   */
  createBonus(data: ICreateBonusPayload): TApiResponse<any> {
    return axios.post(`${bonus}`, data);
  },
  /**
   * 編輯本月加給
   */
  updateBonus(id: string, data: BonusPayload): TApiResponse<any> {
    return axios.patch(`${bonus}/${id}`, data);
  },
  /**
   * 刪除本月加給(停用)
   */
  deleteBonus(id: string): TApiResponse<any> {
    return axios.patch(`${bonus}/${id}/delete`);
  },
});
