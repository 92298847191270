import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import {
  IAuditionFormData,
  IContactInfo,
} from '@type/audition/reservation.type';
import { t } from 'utils/i18n';
export type TInputError = {
  teacher: string;
  examTags: string;
  homeworkTags: string;
  courseReminder: string;
};
interface IReservationCourse {
  isOneClubExistError: boolean;
  infoIsGot: boolean;
  contactError: string;
  contactInfo: IContactInfo | null;
  setContactInfo: Dispatch<SetStateAction<IContactInfo | null>>;
  inputError: TInputError;
  formData: IAuditionFormData;
  setFormData: Dispatch<SetStateAction<IAuditionFormData>>;
  initState: () => void;
  initFormData: () => void;
  initInputError: () => void;
  removeTeacherInfo: () => void;
  removeResourceInfo: () => void;
  handleInputError: (key: keyof TInputError, value: string) => void;
  handleOneClubExistError: (isError: boolean) => void;
  handleInfoIsGot: (isGot: boolean) => void;
  handleContactError: (value: string) => void;
}

const useReservationCourse = (): IReservationCourse => {
  const [isOneClubExistError, setIsOneClubExistError] = useState(false);
  const [infoIsGot, setInfoIsGot] = useState(false);
  const [contactInfo, setContactInfo] = useState<IContactInfo | null>(null);
  const [contactError, setContactError] = useState('');
  const [inputError, setInputError] = useState<TInputError>({
    teacher: t(
      'audition.reservation.components.reservationAddDialog.fieldRequired',
      '此欄位必填',
    ),
    examTags: '',
    homeworkTags: '',
    courseReminder: t(
      'audition.reservation.components.reservationAddDialog.fieldRequired',
      '此欄位必填',
    ),
  });
  // customer 存在的話，代表在 mms 找出來的會員
  const [formData, setFormData] = useState<IAuditionFormData>({
    contactId: '',
    oneClubId: '',
    phone: '',
    studentInfo: {
      isNew: true,
      id: '',
      name: '',
      education: '',
      grade: 1,
      groupId: '',
    },
    resourceInfo: {
      teacher: null,
      seriesTags: [],
      resourceTags: [],
      examTags: [],
    },
  });
  const initFormData = () => {
    setFormData(() => ({
      contactId: '',
      phone: '',
      oneClubId: '',
      studentInfo: {
        isNew: true,
        id: '',
        name: '',
        education: '',
        grade: 1,
        groupId: '',
      },
      resourceInfo: {
        teacher: null,
        seriesTags: [],
        resourceTags: [],
        examTags: [],
      },
    }));
  };
  const initInputError = () => {
    setInputError(() => ({
      teacher: t(
        'audition.reservation.components.reservationAddDialog.fieldRequired',
        '此欄位必填',
      ),
      examTags: '',
      homeworkTags: '',
      courseReminder: t(
        'audition.reservation.components.reservationAddDialog.fieldRequired',
        '此欄位必填',
      ),
    }));
  };
  const initState = () => {
    setContactInfo(null);
    setInfoIsGot(false);
    setIsOneClubExistError(false);
    initInputError();
    initFormData();
  };

  const removeTeacherInfo = () => {
    setFormData(prev => ({
      ...prev,
      resourceInfo: {
        ...prev.resourceInfo,
        teacher: null,
      },
    }));
    setInputError(prev => ({
      ...prev,
      teacher: t(
        'audition.reservation.components.reservationAddDialog.fieldRequired',
        '此欄位必填',
      ),
    }));
  };

  const removeResourceInfo = () => {
    setFormData(prev => ({
      ...prev,
      resourceInfo: {
        ...prev.resourceInfo,
        seriesTags: [],
        examTags: [],
        resourceTags: [],
        homeworkTags: [],
      },
    }));
    setInputError(prev => ({
      ...prev,
      examTags: '',
      homeworkTags: '',
    }));
  };

  const handleInputError = (key: keyof TInputError, value: string) => {
    setInputError(prev => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleOneClubExistError = (isError: boolean) => {
    setIsOneClubExistError(isError);
  };
  const handleInfoIsGot = (isGot: boolean) => {
    setIsOneClubExistError(isGot);
  };
  const handleContactError = (value: string) => {
    setContactError(value);
  };

  return {
    isOneClubExistError,
    infoIsGot,
    contactError,
    contactInfo,
    inputError,
    formData,
    initState,
    initFormData,
    initInputError,
    removeTeacherInfo,
    removeResourceInfo,
    handleInputError,
    handleOneClubExistError,
    handleInfoIsGot,
    handleContactError,
    setContactInfo,
    setFormData,
  };
};

function createService<T>(
  func: (...args: any[]) => T,
  initValue: T | undefined = undefined,
) {
  return createContext(initValue as T);
}
export const ReservationCourseService = createService(useReservationCourse);
export const ReservationCourseProvider: React.FC = ({ children }) => {
  return (
    <ReservationCourseService.Provider value={useReservationCourse()}>
      {children}
    </ReservationCourseService.Provider>
  );
};

export const useReservationCourseService = () =>
  useContext(ReservationCourseService);
