import { VN_LANGUAGE, getUserLanguagePreference } from 'utils/i18n';
const userLanguagePreference = getUserLanguagePreference();

const getDateFormat = () => {
  switch (userLanguagePreference) {
    case VN_LANGUAGE.VI:
      return 'dd/MM/yyyy';
    default:
      return 'yyyy/MM/dd';
  }
};
const getDateTimeFormat = () => {
  switch (userLanguagePreference) {
    case VN_LANGUAGE.VI:
      return 'dd/MM/yyyy HH:mm';
    default:
      return 'yyyy/MM/dd HH:mm';
  }
};
const getMonthYearFormat = () => {
  switch (userLanguagePreference) {
    case VN_LANGUAGE.VI:
      return 'MM/yyyy';
    default:
      return 'yyyy/MM';
  }
};
const dateFormate = getDateFormat();
const dateTimeFormat = getDateTimeFormat();
const monthYearFormat = getMonthYearFormat();
export { dateFormate, dateTimeFormat, monthYearFormat };
