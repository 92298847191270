import { financialAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  SecondRoute,
  laborCostRead,
  performanceExpenseRead,
  revenueRead,
  salaryOverallRead,
  teacherSalaryRead,
} from '../path';
import { Loadable } from '../utils';

const Financial = Loadable(lazy(() => import('pages/financial/Financial')));
const Salary = Loadable(lazy(() => import('pages/financial/salary/Salary')));
const SalaryDetail = Loadable(
  lazy(() => import('pages/financial/salarydetail/SalaryDetail')),
);
const SalaryOverview = Loadable(
  lazy(() => import('pages/financial/salaryoverview/SalaryOverview')),
);
const UnearnedRevenue = Loadable(
  lazy(() => import('pages/financial/unearnedrevenue/UnearnedRevenue')),
);
const UnearnedRevenueDetail = Loadable(
  lazy(
    () => import('pages/financial/unearnedrevenuedetail/UnearnedRevenueDetail'),
  ),
);
const LaborCost = Loadable(
  lazy(() => import('pages/financial/laborcostlist/LaborCost')),
);
const PerformanceExpense = Loadable(
  lazy(() => import('pages/financial/performanceExpense/PerformanceExpense')),
);

const generateFinancialRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(financialAuth)) {
    return null;
  }

  return {
    path: Route.financial,
    element: <Financial />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(teacherSalaryRead)) {
                  return path.FINANCIAL_SALARY;
                }
                if (includeAuth(salaryOverallRead)) {
                  return path.FINANCIAL_SALARY_OVERVIEW;
                }
                if (includeAuth(revenueRead)) {
                  return path.FINANCIAL_UNEARNED_REVENUE;
                }
                if (includeAuth(laborCostRead)) {
                  return path.FINANCIAL_LABOR_COST_LIST;
                }
                if (includeAuth(performanceExpenseRead)) {
                  return path.FINANCIAL_PERFORMANCE_EXPENSE_LIST;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(teacherSalaryRead) &&
        firstFolder.push({
          path: FirstRoute.salary,
          element: <Salary />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: <Navigate to={path.FINANCIAL_SALARY_DETAIL} />,
                index: true,
              },
            ];

            secondFolder.push({
              path: SecondRoute.salarydetail,
              children: [
                {
                  element: <Navigate to={path.NOT_FOUND} />,
                  index: true,
                },
                {
                  path: Route.id,
                  element: <SalaryDetail />,
                },
              ],
            });

            return secondFolder;
          })(),
        });

      includeAuth(salaryOverallRead) &&
        firstFolder.push({
          path: FirstRoute.salaryoverview,
          element: <SalaryOverview />,
        });

      includeAuth(revenueRead) &&
        firstFolder.push({
          path: FirstRoute.unearnedrevenue,
          element: <UnearnedRevenue />,
          children: (() => {
            const secondFolder: RouteObject[] = [
              {
                element: (
                  <Navigate to={path.FINANCIAL_UNEARNED_REVENUE_DETAIL} />
                ),
                index: true,
              },
              {
                path: SecondRoute.unearnedrevenuedetail,
                children: [
                  {
                    element: <Navigate to={path.NOT_FOUND} />,
                    index: true,
                  },
                  {
                    path: Route.id,
                    element: <UnearnedRevenueDetail />,
                  },
                ],
              },
            ];
            return secondFolder;
          })(),
        });

      includeAuth(laborCostRead) &&
        firstFolder.push({
          path: FirstRoute.laborcost,
          element: <LaborCost />,
        });

      includeAuth(performanceExpenseRead) &&
        firstFolder.push({
          path: FirstRoute.performanceexpense,
          element: <PerformanceExpense />,
        });

      return firstFolder;
    })(),
  };
};

export { generateFinancialRoutes };
