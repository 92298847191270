import { lazy } from 'react';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { Auth, Route, teacherTalk } from '../path';
import { Loadable } from '../utils';

const SendbirdTeacher = Loadable(
  lazy(() => import('components/sendbird/SendbirdKit')),
);

const generateSendbirdTeacherRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(teacherTalk)) {
    return null;
  }

  return {
    path: Route.sendbirdteacher,
    element: <SendbirdTeacher />,
  };
};

export { generateSendbirdTeacherRoutes };
