enum REASON_OF_CHANGE_ONEPAY {
  CREATE = 'create', // 新增訂單
  RENEW = 'renew', // 續約
}

enum REASON_OF_CHANGE_ONELIVE {
  RESERVATION = 'reservation', // 堂數扣除
  BACKTRACK = 'backtrack', // 回朔
}

enum REASON_OF_CHANGE {
  CUSTOMER_COMPLAINT = 'customerComplaint', // 品質不佳
  MGM = 'MGM', // 老鼠會
  OTHER = 'other', // 其他
  CANCEL_ORDER = 'cancelOrder', // 取消訂單
  MANUAL_COURSE = 'manualCourse', // 手動調整
  MANUAL_CREATE_SKU = 'manualCreateSku',
  EXCHANGE_SKU = 'exchangeSku', // 兌換商品
}

const ALL_REASON_OF_CHANGE = {
  ...REASON_OF_CHANGE_ONEPAY,
  ...REASON_OF_CHANGE_ONELIVE,
  ...REASON_OF_CHANGE,
};

export {
  REASON_OF_CHANGE_ONEPAY,
  REASON_OF_CHANGE_ONELIVE,
  REASON_OF_CHANGE,
  ALL_REASON_OF_CHANGE,
};

export enum CREDIT_TYPE {
  AUTO_REDUCE = 'autoReduce',
  MANUAL = 'manual',
  ORDER = 'order',
  CONVERT = 'convert',
}

export enum CREDIT_SOURCE {
  LIVE_ONECLASS = 'Live-OneClass',
  ORDER = 'Order',
}
