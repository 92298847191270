import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { IUserData } from 'services/LoginService';
import path, { FirstRoute, Route, SecondRoute } from '../path';
import { Loadable } from '../utils';

const Advertisement = Loadable(lazy(() => import('pages/advertisement/index')));
const LiveApp = Loadable(
  lazy(() => import('pages/advertisement/advertisementLiveApp/LiveApp')),
);
const LiveAppForm = Loadable(
  lazy(
    () =>
      import(
        'pages/advertisement/advertisementLiveApp/liveAppForm/LiveAppForm'
      ),
  ),
);

const generateAdvertisementRoutes = (loginUser: IUserData | null) => {
  if (!loginUser) {
    return null;
  }

  return {
    path: Route.advertisement,
    element: <Advertisement />,
    children: (() => {
      const firstFolder: RouteObject[] = [];

      firstFolder.push({
        path: FirstRoute.liveapp,
        element: <LiveApp />,
        children: (() => {
          const secondFolder: RouteObject[] = [];

          secondFolder.push({
            path: SecondRoute.create,
            element: <LiveAppForm />,
            index: true,
          });

          secondFolder.push({
            path: SecondRoute.edit,
            children: [
              {
                element: <Navigate to={path.NOT_FOUND} />,
                index: true,
              },
              {
                path: Route.id,
                element: <LiveAppForm />,
              },
            ],
          });

          return secondFolder;
        })(),
      });

      return firstFolder;
    })(),
  };
};

export { generateAdvertisementRoutes };
