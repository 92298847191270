import { AxiosInstance } from 'axios';

export default (axios: AxiosInstance, resourceLibrary: string) => ({
  /**
   * 取得學制科目
   */
  getEduSubject(): any {
    return axios.get(`${resourceLibrary}/Open/Selection/EduSubject`);
  },
});
