import { Mui } from '@onedesign/ui';
import { styled } from '@mui/material/styles';
import { Icon, SvgName } from 'components/Icon';
import { List, ListItemButton, ListItemText, Popover } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  sx?: any;
  dark?: Boolean;
};

const { Typography, Box } = Mui;

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const AVAIL_LANGUAGES = [
  {
    label: 'EN',
    labelFull: 'English',
    value: 'en',
  },
  {
    label: '中文',
    labelFull: '中文 (繁體)',
    value: 'zh',
  },
  {
    label: 'VN',
    labelFull: 'Tiếng Việt',
    value: 'vi',
  },
];

export const LanguageSwitcher = ({ sx, dark = false }: Props) => {
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChangeLanguage = async (language: {
    label?: string;
    labelFull?: string;
    value: any;
  }) => {
    localStorage.setItem('lang', language.value);
    await i18n.changeLanguage(language.value);
    await i18n.reloadResources();
    setAnchorEl(null);
    window.location.reload();
  };

  return (
    <>
      <StyledBox sx={sx} onClick={handleClick}>
        <Icon
          sx={{
            marginRight: '1rem',
            width: 20,
            height: 20,
            color: dark ? '#637381' : 'white',
          }}
          name={SvgName.Language}
        />
        <Typography
          variant="subtitle2"
          sx={{ color: dark ? '#212B36' : 'white' }}
        >
          {AVAIL_LANGUAGES.find(item => item.value === i18n.language)?.label}
        </Typography>
        <Icon
          name={SvgName.ExpandMore}
          sx={{ color: dark ? '#212B36' : 'white' }}
        />
      </StyledBox>
      <Popover
        id="language-switcher"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ mt: 1 }}
        PaperProps={{
          style: { width: 200 },
        }}
      >
        <List sx={{ padding: 1 }}>
          {AVAIL_LANGUAGES.map(language => (
            <ListItemButton
              sx={{ borderRadius: 1 }}
              key={language.value}
              selected={language.value === i18n.language}
              onClick={() => handleChangeLanguage(language)}
            >
              <ListItemText primary={language.labelFull} />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </>
  );
};
