import { Icon, SvgName } from 'components/Icon';

const getIcon = (name: SvgName) => <Icon name={name} />;

export const ICONS = {
  customer: getIcon(SvgName.Customer),
  points: getIcon(SvgName.Point),
  calendar: getIcon(SvgName.Calendar),
  introduction: getIcon(SvgName.Introduction),
  chartPie: getIcon(SvgName.ChartPie),
  userConfig: getIcon(SvgName.UserConfig),
  teacherManagement: getIcon(SvgName.Badge),
  financial: getIcon(SvgName.MoneyBill),
  badgeCentral: getIcon(SvgName.Pastafarianism),
  tutorialCenter: getIcon(SvgName.School),
  advertisement: getIcon(SvgName.Advertisement),
  customerTicket: getIcon(SvgName.CustomerTicket),
  oneAnswer: getIcon(SvgName.OneAnswer),
};
