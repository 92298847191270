import { financialAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  laborCostRead,
  performanceExpenseRead,
  revenueRead,
  salaryOverallRead,
  teacherSalaryRead,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateFinancialNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(financialAuth)) {
    return null;
  }

  return {
    title: RouteName.financial,
    path: path.FINANCIAL,
    icon: ICONS.financial,
    children: (() => {
      const newNav = [];

      includeAuth(teacherSalaryRead) &&
        newNav.push({
          title: FirstRouteName.salary,
          path: path.FINANCIAL_SALARY,
        });
      includeAuth(salaryOverallRead) &&
        newNav.push({
          title: FirstRouteName.salaryoverview,
          path: path.FINANCIAL_SALARY_OVERVIEW,
        });
      includeAuth(revenueRead) &&
        newNav.push({
          title: FirstRouteName.unearnedrevenue,
          path: path.FINANCIAL_UNEARNED_REVENUE,
        });

      includeAuth(laborCostRead) &&
        newNav.push({
          title: FirstRouteName.laborcost,
          path: path.FINANCIAL_LABOR_COST_LIST,
        });

      includeAuth(performanceExpenseRead) &&
        newNav.push({
          title: FirstRouteName.performanceexpense,
          path: path.FINANCIAL_PERFORMANCE_EXPENSE_LIST,
        });

      return newNav;
    })(),
  };
};

export { generateFinancialNav };
