export enum BUSINESS_TAB {
  GROUP = '組績',
  PERSONAL = '個人',
}

export enum STATISTIC_PERIOD_RANGE {
  FIRST = '00:00~07:59',
  SECOND = '08:00~11:59',
  THIRD = '12:00~15:59',
  FOURTH = '16:00~19:59',
  FIFTH = '20:00~23:59',
}
export enum STATISTIC_PERIOD {
  FIRST = 8,
  SECOND = 4,
  THIRD = 4,
  FOURTH = 4,
  FIFTH = 4,
}
export enum DEAL_STATUS {
  COMPLETE = '93',
  DEAL = '95',
  RESERVED = 'NEW',
}

export enum PERSONAL_TABLE_COLUMN {
  department = '組別',
  name = '負責人',
  dialNumber = '撥號次數',
  callNumber = '通話次數',
  duration = '通話總分鐘數',
  durationPerTime = '通話單次平均分鐘數',
  inviteeAuditionNumber = '邀約試聽人數',
  reservationAuditionNumber = '預計試聽人數',
  completeAuditionNumber = '完成試聽人數',
  dealNumber = '成交筆數',
  revenue = '營業額',
}

export enum GROUP_TABLE_FIRST_ROW {
  dialNumber = '撥號次數',
  callNumber = '通話次數',
  duration = '通話總分鐘數',
  durationPerTime = '通話單次平均分鐘數',
  inviteeAuditionNumber = '邀約試聽人數',
  reservationAuditionNumber = '預計試聽人數',
  completeAuditionNumber = '完成試聽人數',
  oldDealNumber = '舊單成交',
  newDealNumber = '新單成交',
  dealNumber = '成交筆數',
  revenue = '營業額',
}

export enum Order_DEAL_STATUS {
  new = '新單成交',
  old = '就單成交',
}
