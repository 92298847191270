import { electroniccommerceAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  electroniccommerceCommodity,
  electroniccommerceOrder,
  electroniccommerceSetting,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateElectronicCommerceNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(electroniccommerceAuth)) {
    return null;
  }

  return {
    title: RouteName.electroniccommerce,
    path: path.ELECTRONICCOMMERCE,
    icon: ICONS.introduction,
    children: (() => {
      const newNav = [];

      includeAuth(electroniccommerceOrder) &&
        newNav.push({
          title: FirstRouteName.order,
          path: path.ELECTRONICCOMMERCE_ORDER,
        });

      includeAuth(electroniccommerceCommodity) &&
        newNav.push({
          title: FirstRouteName.commodity,
          path: path.ELECTRONICCOMMERCE_COMMODITY,
        });

      includeAuth(electroniccommerceSetting) &&
        newNav.push({
          title: FirstRouteName.setting,
          path: path.ELECTRONICCOMMERCE_SETTING,
        });

      newNav.push({
        title: FirstRouteName.refundAssistance,
        path: path.ELECTRONICCOMMERCE_REFUND_ASSISTANCE,
      });

      return newNav;
    })(),
  };
};

export { generateElectronicCommerceNav };
