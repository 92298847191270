import { AxiosInstance } from 'axios';

const config = {
  maxRedirects: 0,
  headers: {
    'Content-Type': 'text/plain; charset=utf-8',
  },
};

export default (axios: AxiosInstance, google: string) => ({
  /**
   * google 表單
   */
  postSheet(data: any): any {
    return axios.post(google, data, config);
  },
});
