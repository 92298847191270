import path, { FirstRouteName, RouteName } from 'routes/path';
import { ICONS } from './icon';

const generateOneAnswerNav = () => {
  return {
    title: RouteName.oneanswer,
    path: path.ONE_ANSWER,
    icon: ICONS.oneAnswer,
    children: (() => {
      const newNav = [];

      newNav.push({
        title: FirstRouteName.record,
        path: path.ONE_ANSWER_RECORD,
      });

      return newNav;
    })(),
  };
};

export { generateOneAnswerNav };
