import { DEAL_STATUS } from 'constants/daily.constants';
import { BITRIX_CONTACT_MAP } from 'constants/bitrix.constants';
import { AxiosInstance } from 'axios';
import { IMemberByIdResponse } from '@type/bitrix';
import {
  IDealResponse,
  IMemberResponse,
  IPhoneResponse,
  IPeriodParams,
} from '@type/report/daily.type';
import { EApiStatus, TApiResponse } from '@type/common';
import { isArray, mergeWith } from 'lodash';
import {
  IContactInfo,
  IStudentInfo,
  IUpdateAuditionInformationPayload,
} from '@type/audition/reservation.type';

export default (axios: AxiosInstance, bitrix: string) => ({
  // attributes type
  /**
   * 取得撥打電話資料
   */
  getPhoneData(data: IPeriodParams): TApiResponse<IPhoneResponse> {
    const params = {
      '>CALL_START_DATE': data.startDate,
      '<CALL_START_DATE': data.endDate,
    };
    return axios.get(`${bitrix}/statistic/phone`, {
      params,
    });
  },
  /**
   * 取得成交資料
   */
  getDealData(data: IPeriodParams): TApiResponse<IDealResponse> {
    const params = {
      '>DATE_CREATE': data.startDate,
      '<DATE_CREATE': data.endDate,
    };
    return axios.get(`${bitrix}/statistic/deals`, {
      params,
    });
  },

  /**
   * 取得邀約試聽人數
   */
  getReservedDealData(data: IPeriodParams): TApiResponse<IDealResponse> {
    const params = {
      '>DATE_CREATE': data.startDate,
      '<DATE_CREATE': data.endDate,
      STAGE_ID: DEAL_STATUS.RESERVED,
    };
    return axios.get(`${bitrix}/statistic/deals`, {
      params,
    });
  },

  getAuditionData(
    data: IPeriodParams & { status?: DEAL_STATUS },
  ): TApiResponse<IDealResponse[]> {
    const auditionDateFields = [
      'UF_CRM_1620201914543',
      'UF_CRM_1622107247',
      'UF_CRM_1622821564',
      'UF_CRM_1622821580',
    ];

    const createPromise = (field: string) => {
      const key1 = `>${field}`;
      const key2 = `<${field}`;
      return axios.get(`${bitrix}/statistic/deals`, {
        params: {
          [key1]: data.startDate,
          [key2]: data.endDate,
          ...(data.status ? { STAGE_ID: data.status } : {}),
        },
      });
    };
    const promises = auditionDateFields.map(createPromise);

    function concatArr(objValue: IDealResponse[], srcValue: IDealResponse[]) {
      if (isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    }

    // @ts-ignore
    return Promise.all(promises).then((res: TApiResponse[]) => {
      const isSuccess = res.every(item => item.status === EApiStatus.SUCCESS);
      return {
        status: isSuccess ? EApiStatus.SUCCESS : EApiStatus.FAILURE,
        data: res
          .map(item => item.data)
          .reduce((curr, item) => mergeWith(curr, item, concatArr), {}),
      };
    });
  },
  /**
   * 取得部門人員資料
   */
  getMemberData(): TApiResponse<IMemberResponse[]> {
    return axios.get(`${bitrix}/crm/members`);
  },
  /**
   * 透過 bx contact data 的 ASSIGNED_BY_ID 取得負責人資料
   */
  async getMemberDataById(id: string): TApiResponse<IMemberByIdResponse> {
    const response = (await axios.get(`${bitrix}/crm/member/${id}`)) as {
      status: EApiStatus;
      data: any;
    };
    if (response.status === EApiStatus.SUCCESS) {
      const data = response.data[0];
      return Promise.resolve({
        status: EApiStatus.SUCCESS,
        data: {
          id: data.ID,
          name: `${data.LAST_NAME}${data.NAME}`,
        } as IMemberByIdResponse,
      });
    } else {
      return Promise.resolve({
        status: EApiStatus.FAILURE,
        data: {} as IMemberByIdResponse,
      });
    }
  },
  /**
   * 取得單一聯絡人資料
   */
  async getContactMember(id: string): TApiResponse<IContactInfo> {
    const response = (await axios.get(`${bitrix}/crm/contact/${id}`)) as {
      status: EApiStatus;
      data: any;
    };
    if (response.status === EApiStatus.SUCCESS) {
      const data = response.data;
      const studentInfos: IStudentInfo[] = [];
      return Promise.resolve({
        status: EApiStatus.SUCCESS,
        data: {
          name: (data?.LAST_NAME || '') + (data?.NAME || ''),
          email: data.EMAIL?.[0]?.VALUE,
          phone: data.PHONE?.[0]?.VALUE?.replace(/-/g, ''),
          phones:
            data.PHONE?.map((item: any) => item.VALUE?.replace(/-/g, '')) || [],
          oneClubId: data?.[BITRIX_CONTACT_MAP.ONECLUB_ID],
          notice: data?.[BITRIX_CONTACT_MAP.DESCRIPTION],
          consultantId: data.ASSIGNED_BY_ID,
          studentInfos,
        } as IContactInfo,
      });
    } else {
      return Promise.resolve({
        status: EApiStatus.FAILURE,
        data: {} as IContactInfo,
      });
    }
  },
  /**
   * 透過訂單編號取得資料
   */
  async getOrderMember(dealId: string): TApiResponse<any> {
    return axios.get(`${bitrix}/crm/contact/dealId/${dealId}`);
  },
  /**
   * 更新BX 試聽相關欄位
   */
  updateAuditionInformation(
    data: IUpdateAuditionInformationPayload,
  ): TApiResponse<any> {
    return axios.patch(`${bitrix}/auditionInformation`, data);
  },
  /**
   * 更新BX 交易卡牌試聽資訊
   */
  updateAuditionInfo(
    dealId: string,
    payload: {
      auditionInformation: string;
    },
  ): TApiResponse<any> {
    return axios.patch(
      `${bitrix}/crm/deal/${dealId}/auditionInformation`,
      payload,
    );
  },
});
