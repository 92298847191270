import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  GetNowOrdersPayload,
  GetNowOrdersResponse,
  GetNowOrderByIdResponse,
  UpdateNowOrderPayload,
  GetNowOrderLogParams,
  GetNowOrderLogResponse,
} from './order.type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  /* 取得會員下的 Now 訂單 */
  getNowOrderList(
    params: GetNowOrdersPayload,
  ): TApiResponse<GetNowOrdersResponse> {
    return axios.get(
      `${apiPrefix}/backend/oneclass-now/order/getNowOrderList`,
      { params },
    );
  },
  /**
   * 取得 now 訂單
   */
  getNowOrdersById(orderNumber: string): TApiResponse<GetNowOrderByIdResponse> {
    return axios.get(
      `${apiPrefix}/backend/oneclass-now/order/getNowOrderDetail/${orderNumber}`,
    );
  },
  /**
   * 更新 now 訂單
   */
  updateNowOrder(
    orderNumber: string,
    data: UpdateNowOrderPayload,
  ): TApiResponse<GetNowOrderByIdResponse> {
    return axios.patch(
      `${apiPrefix}/backend/oneclass-now/order/updateOrder/${orderNumber}`,
      data,
    );
  },
  /**
   * 取得 now 訂單 log
   */
  getNowOrderLog(
    params: GetNowOrderLogParams,
  ): TApiResponse<GetNowOrderLogResponse> {
    return axios.get(
      `${apiPrefix}/backend/oneclass-now/order-log/getNowOrderLogList`,
      { params },
    );
  },

  /** now 訂單退費 */
  refundOrderCancelCourse(orderId: string): Omit<TApiResponse<never>, 'data'> {
    return axios.post(
      `${apiPrefix}/backend/oneclass-now/order/refundOrder/${orderId}`,
    );
  },
});
