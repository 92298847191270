import { ALL_REASON_OF_CHANGE_TEXT } from 'constants/points.constants';
import { createContext, useContext, useState } from 'react';
import { ALL_REASON_OF_CHANGE } from '@type/point/points.type';
import {
  IUserPoints,
  IUserPointsParams,
  IPointsRecords,
  IApiPointsRecords,
  IPointsRecordsRes,
} from '@type/point/PointsRepository.type';
import { IBackstageUserPoints } from 'api/backstage';
import axios from 'plugins/api/axios';
import { EApiStatus } from '@type/common';

interface IState {
  userPoints: IUserPoints[] | null;
  renewPoints: IUserPoints | null;
  pointsRecords: IPointsRecordsRes | null;
}

interface IFunction {
  emptyPointsData: () => void;
  getUserPoints: (userId: IBackstageUserPoints) => void;
  updateUserPoints: (params: IUserPointsParams) => void;
  getPointsRecords: (
    params: IApiPointsRecords,
  ) => Promise<void | IPointsRecords>;
}

const { backstage } = axios;

const usePoints = (): [IState, IFunction] => {
  const [userPoints, setUserPoints] = useState<IUserPoints[] | null>(null);
  const [renewPoints, setRenewPoints] = useState<IUserPoints | null>(null);
  const [pointsRecords, setPointsRecords] = useState<IPointsRecordsRes | null>(
    null,
  );

  const emptyPointsData = () => {
    setUserPoints(null);
    setRenewPoints(null);
    setPointsRecords(null);
  };

  const getUserPoints = async (userId: IBackstageUserPoints) => {
    const { status, data } = await backstage.getUserPoints(userId);
    if (status === EApiStatus.SUCCESS) {
      setUserPoints(data);
    }
  };

  const updateUserPoints = async (params: IUserPointsParams) => {
    const { status, data } = await backstage.updateUserPoints(params);
    if (status === EApiStatus.SUCCESS) {
      setRenewPoints(data);
    }
  };

  const getPointsRecords = async (params: IApiPointsRecords) => {
    const { pointId, limit, page } = params;
    const skip = page * limit;
    const { status, data } = await backstage.getPointsRecords(
      { limit, skip },
      pointId,
    );
    if (status === EApiStatus.SUCCESS) {
      const { records, total } = data;
      const recordList = records.map((item: IPointsRecords) => {
        let memoMatch = '';
        switch (item.changeReason) {
          case ALL_REASON_OF_CHANGE.CREATE:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.CREATE]
            }：${item.remark}`;
            break;
          case ALL_REASON_OF_CHANGE.RENEW:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.RENEW]
            }：${item.remark}`;
            break;
          case ALL_REASON_OF_CHANGE.RESERVATION:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.RESERVATION]
            }：${item.remark}`;
            break;
          case ALL_REASON_OF_CHANGE.BACKTRACK:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.BACKTRACK]
            }：${item.remark}`;
            break;
          case ALL_REASON_OF_CHANGE.CUSTOMER_COMPLAINT:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.CUSTOMER_COMPLAINT]
            }：${item.remark}`;
            break;
          case ALL_REASON_OF_CHANGE.MGM:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.MGM]
            }：${item.remark}`;
            break;
          case ALL_REASON_OF_CHANGE.OTHER:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.OTHER]
            }：${item.remark}`;
            break;
          case ALL_REASON_OF_CHANGE.CANCEL_ORDER:
            memoMatch = `${
              ALL_REASON_OF_CHANGE_TEXT[ALL_REASON_OF_CHANGE.CANCEL_ORDER]
            }：${item.remark}`;
            break;
          default:
            memoMatch = item.remark;
            break;
        }
        const memo = memoMatch.split(/,/g);

        return {
          ...item,
          transactionPoint:
            item.afterModifyInfo.point - item.beforeModifyInfo.point,
          transactionActivePoint:
            item.afterModifyInfo.activePoint -
            item.beforeModifyInfo.activePoint,
          transactionDate:
            item.afterModifyInfo.expired !== item.beforeModifyInfo.expired
              ? item.afterModifyInfo.expired
              : 0,
          memo,
        };
      });

      setPointsRecords({ records: recordList, total });
    }
  };

  return [
    {
      userPoints,
      renewPoints,
      pointsRecords,
    },
    {
      emptyPointsData,
      getUserPoints,
      updateUserPoints,
      getPointsRecords,
    },
  ];
};

function createService<T>(
  func: (...args: any[]) => T,
  initialValue: T | undefined = undefined,
) {
  return createContext(initialValue as T);
}
const PointsService = createService(usePoints);

export const PointsServiceProvider: React.FC = ({ children }) => {
  return (
    <PointsService.Provider value={usePoints()}>
      {children}
    </PointsService.Provider>
  );
};

export const usePointsService = () => useContext(PointsService);
