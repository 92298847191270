import { AxiosInstance } from 'axios';
import tos from './tos';
import order from './order';

/**
 * Swagger: https://api-dev.oneclass.co/oneclass-now/swagger/index.html#/
 */
export default (axios: AxiosInstance, apiPrefix: string) => ({
  tos: tos(axios, apiPrefix),
  order: order(axios, apiPrefix),
});
