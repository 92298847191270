import { Dialogs } from 'components/Dialogs';
import { ChangeEvent } from 'react';
import { Mui } from '@onedesign/ui';
import { Loader } from 'components/Loader';

const { TextField } = Mui;

type TProps = {
  open: boolean;
  onClose: () => void;
  onDisConfirm: () => void;
  handleEditNickname: () => void;
  editNickname: string;
  setEditNickname: (value: string) => void;
  loading: boolean;
};
const EditNicknameModal = ({
  open,
  onClose,
  onDisConfirm,
  handleEditNickname,
  editNickname,
  setEditNickname,
  loading,
}: TProps) => {
  const handleNicknameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setEditNickname(event.target.value);
  };

  return (
    <Dialogs
      open={open}
      title="編輯暱稱"
      close={{ text: 'x', action: () => onClose() }}
      confirm={{
        color: 'primary',
        text: '確定',
        action: () => handleEditNickname(),
        disabled: loading,
      }}
      disConfirm={{
        color: 'inherit',
        text: '取消',
        action: () => onDisConfirm(),
      }}
    >
      {loading && <Loader />}
      {!loading && (
        <TextField
          label="暱稱*"
          value={editNickname}
          onChange={handleNicknameInput}
        />
      )}
    </Dialogs>
  );
};

export default EditNicknameModal;
