import { authorityAuth } from 'constants/auth/auth';
import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import path, {
  Auth,
  FirstRoute,
  Route,
  authorityDepartment,
  authorityEmployee,
  authorityRole,
} from '../path';
import { Loadable } from '../utils';

const Authority = Loadable(lazy(() => import('pages/authority/Authority')));
const AuthorityEmployee = Loadable(
  lazy(() => import('pages/authority/employee/Employee')),
);
const AuthorityRole = Loadable(lazy(() => import('pages/authority/role/Role')));
const AuthorityDepartment = Loadable(
  lazy(() => import('pages/authority/department/Department')),
);

const generateAuthorityRoutes = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(authorityAuth)) {
    return null;
  }

  return {
    path: Route.authority,
    element: <Authority />,
    children: (() => {
      const firstFolder: RouteObject[] = [
        {
          element: (
            <Navigate
              to={(() => {
                if (includeAuth(authorityEmployee)) {
                  return path.AUTHORITY_EMPLOYEE;
                }
                if (includeAuth(authorityRole)) {
                  return path.AUTHORITY_ROLE;
                }
                if (includeAuth(authorityDepartment)) {
                  return path.AUTHORITY_DEPARTMENT;
                }
                return path.PERMISSION_ABNORMAL;
              })()}
            />
          ),
          index: true,
        },
      ];

      includeAuth(authorityEmployee) &&
        firstFolder.push({
          path: FirstRoute.employee,
          element: <AuthorityEmployee />,
        });

      includeAuth(authorityRole) &&
        firstFolder.push({
          path: FirstRoute.role,
          element: <AuthorityRole />,
        });

      includeAuth(authorityDepartment) &&
        firstFolder.push({
          path: FirstRoute.department,
          element: <AuthorityDepartment />,
        });

      return firstFolder;
    })(),
  };
};

export { generateAuthorityRoutes };
