import { t } from 'utils/i18n';
export enum ORDER_PAYMENT_STATUS {
  NORMAL = 'normal',
  REFUND = 'refund',
  SUSPEND = 'suspend',
  FINISH = 'finish',
}

export const REVENUES_STATUS_LABEL = {
  [ORDER_PAYMENT_STATUS.NORMAL]: () =>
    t('financial.unearnedRevenue.revenueStatus.normal', '正常繳款'),
  [ORDER_PAYMENT_STATUS.REFUND]: () =>
    t('financial.unearnedRevenue.revenueStatus.refund', '退費'),
  [ORDER_PAYMENT_STATUS.SUSPEND]: () =>
    t('financial.unearnedRevenue.revenueStatus.suspend', '暫停'),
  [ORDER_PAYMENT_STATUS.FINISH]: () =>
    t('financial.unearnedRevenue.revenueStatus.finish', '已完結'),
};

export enum AMT_TYPE {
  RECOGNIZED_AMT = 'recognizedAmt',
  PREPAID_AMT = 'prepaidAmt',
  RECEIVABLES_AMT = 'receivablesAmt',
}

export const AMT_TYPE_TEXT = {
  [AMT_TYPE.RECOGNIZED_AMT]: () =>
    t('financial.unearnedRevenue.amtType.recognizedAmt', '認列收入'),
  [AMT_TYPE.PREPAID_AMT]: () =>
    t('financial.unearnedRevenue.amtType.prepaidAmt', '預收收入'),
  [AMT_TYPE.RECEIVABLES_AMT]: () =>
    t('financial.unearnedRevenue.amtType.receivablesAmt', '暫收收入'),
};
