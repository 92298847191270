import { customerVisitorAuth } from 'constants/auth/auth';
import path, {
  Auth,
  FirstRouteName,
  RouteName,
  customerDataRead,
  visiterDataRead,
} from 'routes/path';
import { checkPermissionsInAuth } from 'utils/helpr/permission';
import { ICONS } from './icon';

const generateCustomerVisitorNav = (permissions: Auth[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (!includeAuth(customerVisitorAuth)) {
    return null;
  }

  return {
    title: RouteName.customer,
    path: path.CUSTOMER,
    icon: ICONS.customer,
    children: (() => {
      const newNav = [];
      includeAuth(customerDataRead) &&
        newNav.push({
          title: FirstRouteName.customerlist,
          path: path.CUSTOMER_LIST,
        });

      includeAuth(visiterDataRead) &&
        newNav.push({
          title: FirstRouteName.visitorlist,
          path: path.CUSTOMER_VISITOR_LIST,
        });
      return newNav;
    })(),
  };
};

export { generateCustomerVisitorNav };
