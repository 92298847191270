import {
  auditionAuth,
  authorityAuth,
  customerVisitorAuth,
  dataCentralAuth,
  electroniccommerceAuth,
  financialAuth,
  reportAuth,
  shipManagementAuth,
  teachersManagementAuth,
  tutorialCenterAuth,
} from 'constants/auth/auth';
import { intersection } from 'lodash';
import path, { Auth } from 'routes/path';

export const createPermissionChecker = (userPermissions: Auth[]) => {
  return (requiredPermission: Auth[]) => {
    return intersection(userPermissions, requiredPermission).length > 0;
  };
};
export const checkPermissionsInAuth =
  (permissions: string[]) => (auth: string[]) => {
    return (
      permissions.length > 0 &&
      auth.some((item: any) => permissions.includes(item))
    );
  };
export const resolveAuthorizedPath = (permissions: string[]) => {
  const includeAuth = checkPermissionsInAuth(permissions);

  if (includeAuth(dataCentralAuth)) {
    return path.DATA_CENTRAL;
  }
  if (includeAuth(customerVisitorAuth)) {
    return path.CUSTOMER;
  }
  if (includeAuth(auditionAuth)) {
    return path.AUDITION;
  }
  if (includeAuth(tutorialCenterAuth)) {
    return path.TUTORIAL_CENTER_FORMAL_COURSE;
  }
  if (includeAuth(electroniccommerceAuth)) {
    return path.ELECTRONICCOMMERCE;
  }
  if (includeAuth(teachersManagementAuth)) {
    return path.TEACHER_MANAGEMENT;
  }
  if (includeAuth(reportAuth)) {
    return path.REPORT;
  }
  if (includeAuth(financialAuth)) {
    return path.FINANCIAL;
  }
  if (includeAuth(authorityAuth)) {
    return path.AUTHORITY;
  }
  if (includeAuth(shipManagementAuth)) {
    return path.MEDAL_CENTRAL;
  }

  return path.ONE_ANSWER;

  // return path.PERMISSION_ABNORMAL;
};

export const routerTo = (
  permissions: string[],
  navigate: (path: any) => void,
) => navigate(resolveAuthorizedPath(permissions));
