/// rest/9/aqw6va2ru41h8d7t/crm.deal.get.json
import { AxiosInstance } from 'axios';
import { IOneClubAccountPayload } from '@type/audition/vipPass.type';
import { TApiResponse } from '@type/common';
export default (axios: AxiosInstance, oneClubSNS: string) => ({
  // attributes type
  /**
   * 註冊OneClub帳號
   */
  registerOneClubAccount(payload: IOneClubAccountPayload): TApiResponse<any> {
    return axios.post(`${oneClubSNS}/signUpMobileSimple`, payload);
  },
});
