import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import {
  IGetPerformacesResponse,
  IPerformance,
  IUpdatePerformancePayload,
} from '@type/report/performance.type';

export default (axios: AxiosInstance, performance: string) => ({
  /**
   * 取得業績瀏覽資料（所有業績）
   */
  getPerformances(params: any): TApiResponse<IGetPerformacesResponse> {
    return axios.get(`${performance}/performance`, { params });
  },
  /**
   * 取得業績瀏覽資料（該人員負責的業績）
   */
  getDepartmentPerformances(params: any): TApiResponse<IPerformance[]> {
    return axios.get(`${performance}/performance/department-performances`, {
      params,
    });
  },
  /**
   * 取得業績瀏覽資料
   */
  getPerformance(id: string): TApiResponse<IPerformance> {
    return axios.get(`${performance}/performance/${id}`);
  },
  /**
   * 更新業績瀏覽資料
   */
  updatePerformance(
    id: string,
    payload: IUpdatePerformancePayload,
  ): TApiResponse<IPerformance> {
    return axios.patch(`${performance}/performance/${id}`, payload);
  },
  /**
   * 匯出業績瀏覽資料
   */
  async exportPerformances(params: any): Promise<any> {
    const link = document.createElement('a');
    const excel = await axios.get(`${performance}/performance/exportExcel`, {
      params,
      responseType: 'blob',
    });
    if (!excel) {
      return false;
    }
    const blob = new Blob([excel as unknown as BlobPart]);
    link.style.display = 'none';
    link.href = URL.createObjectURL(blob);
    link.download = `performance.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return true;
  },
});
