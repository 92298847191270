import { Suspense, ElementType } from 'react';
import { LoadingScreen } from '@onedesign/ui';

export const Loadable = (Component: ElementType) => (props: any) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export type IncludeAuth = (auth: string[]) => boolean;
