import { AxiosInstance } from 'axios';
import { TApiResponse } from '@type/common';
import { GetNowOrderListParams, GetNowOrderListResponse } from './type';

export default (axios: AxiosInstance, apiPrefix: string) => ({
  getNowOrderList(
    params: GetNowOrderListParams,
  ): TApiResponse<GetNowOrderListResponse> {
    return axios.get(
      `${apiPrefix}/backend/oneclass-now/order/getNowOrderList`,
      {
        params,
      },
    );
  },
});
