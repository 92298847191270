import { AxiosInstance } from 'axios';
import { ICreateRoomPayload } from '@type/funLearning.type';

export default (axios: AxiosInstance, funLearning: string) => ({
  /**
   * 新增房間
   */
  createRoom(data: ICreateRoomPayload): any {
    return axios.post(`${funLearning}/Classroom/createRoom`, data);
  },
});
