import LRU from 'lru-cache';
import { AxiosInstance } from 'axios';

export interface IScheduleParams {
  startTime: number;
  endTime: number;
}
export interface ICreateScheduleParams {
  startTime: number;
  endTime: number;
  userId: string;
}
export interface IDeleteScheduleParams {
  remarks: string;
}

export interface IBitrixParams {
  id: string;
}

export interface ITutorials {
  userId: string;
  name: string;
  education: string;
  subject: string;
  project: string;
  projectId: string;
  isForced: boolean;
  startTime: number;
  endTime: number;
  remarks: string;
  year: string;
  usagePeriodStatus: string;
  courseName: string;
  tutorialName: string;
  crmId: string;
  bookId: string;
  advisor: string;
  advisorId: string;
  audiovisualNumber: string;
  classType: string;
}

const NUMBER = {
  QUANTITY: {
    FIVE: 5,
    HUNDRED: 100,
  },
  TIME: {
    ONE_MIN: 1000 * 60,
  },
};

const KEY = {
  TEACHER_SCHEDULE: 'TEACHER_SCHEDULE',
};

const teacherCache = new LRU({
  max: NUMBER.QUANTITY.HUNDRED,
  ttl: NUMBER.QUANTITY.FIVE * NUMBER.TIME.ONE_MIN,
});

export default (axios: AxiosInstance, teacher: string) => ({
  /**
   * 取得老師清單
   */
  getTeacherList(): any {
    return axios.get(`${teacher}/trials`);
  },
  /**
   * 取得時間區間的安排事件
   */
  getTeacherSchedule(data: IScheduleParams): any {
    const cacheKey = `${KEY.TEACHER_SCHEDULE}_${data.startTime}_${data.endTime}`;
    if (teacherCache.has(cacheKey)) {
      return teacherCache.get(cacheKey);
    }
    return axios
      .get(`${teacher}/trials-schedule`, { params: data })
      .then((res: any) => {
        if (res.status === 'success') {
          teacherCache.set(cacheKey, res);
        }
        return res;
      });
  },
  /**
   * 新增事件(排班)
   */
  createSchedule(data: ICreateScheduleParams): any {
    return axios
      .post(`${teacher}/trial/appointments`, data)
      .then((res: any) => {
        if (res.status === 'success') {
          teacherCache.clear();
        }
        return res;
      });
  },
  /**
   * 刪除事件
   */
  deleteSchedule(data: IDeleteScheduleParams, id: string): any {
    return axios
      .delete(`${teacher}/trial/appointments/${id}`, { data })
      .then((res: any) => {
        if (res.status === 'success') {
          teacherCache.clear();
        }
        return res;
      });
  },
  /**
   * 取得 Bitrix 訂單
   * @returns
   */
  getBitrixOrder(data: IBitrixParams): any {
    return axios.get(`${teacher}/bitrix24Order`, { params: data });
  },
  /**
   * 新增預約(排課)
   */
  createTutorials(data: ITutorials): any {
    return axios.post(`${teacher}/trial/tutorials`, data).then((res: any) => {
      if (res.status === 'success') {
        teacherCache.clear();
      }
      return res;
    });
  },
});
